.custom-radio{
    position: relative;
    padding-left: calc(43rem/16);
    display: flex;
    align-items: center;
    min-height: calc(30rem/16);
}

.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}

.custom-radio:after {
     clear: both;
}

.custom-radio>label{
    cursor: pointer;
}

.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: none;
    background: #ffffff;
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    top: 0;
    box-shadow: 0 calc(4rem/16) calc(10rem/16) 0 rgba(17,20,24,0.15);
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(14rem/16);
    height: calc(14rem/16);
    border-radius: 50%;
    background: #FFFFFF;
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:checked ~ .custom-radio__box {
    background-color: var(--color-default);
}
.has-error .custom-radio__box {
    border: calc(1rem/16) solid var(--color-danger);
}
.custom-radio__text {
    display: block;
    overflow: hidden;
    line-height: 1.3;
}
.form-check-inline .custom-radio__input {
    position: absolute;
}
.custom-radio__label-img {
    max-height: calc(22rem/16);
}