.bootstrap-select .dropdown-menu li a {
    margin-bottom: 1px;
}
.bootstrap-select.form-control {
    border-bottom: calc(1rem/16) solid var(--color-middle-grey);
}
.bootstrap-select .dropdown-menu .selected {
    background-color: var(--color-primary);
    color: var(--color-primary-contrast);
}
.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    padding-right: 1rem;
    text-overflow: ellipsis;
}
.bootstrap-select>.dropdown-toggle:after {
    display: none;
}
.bootstrap-select>.dropdown-toggle {
    padding-left: 0;
    letter-spacing: 0;
}
.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
    top: 9px;
}
.bootstrap-select .bs-ok-default:after {
    content: var(--icon-check);
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: auto;
    height: auto;
    transform: none;
    border: none;
    font-size: .9em;
}