.cart__summary {
    background-color: var(--color-white);
    color:var(--color-dark);
    line-height: 1.4;
    position: relative;
    @media screen and (max-width: 767px) {
        margin-top: calc(20rem/16);
    }
}

.cart__summary-heading {
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
}
.cart__summary-price {
    font-family: var(--font-default-bold);
}
.cart__summary__header {
    padding: calc(42rem/16) calc(40rem/16) calc(25rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.cart__summary--has-edit .cart__summary__header {
    @media screen and (max-width: 767px) {
        padding: calc(42rem/16) calc(20rem/16) calc(20rem/16);
    }
}
.cart__summary__body {
    padding: calc(20rem/16) calc(40rem/16);
    border-top: calc(1rem/16) solid var(--color-light-grey);
    border-bottom: calc(1rem/16) solid var(--color-light-grey);
    font-size: calc(14rem/16);
    line-height: 1.4;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.cart__summary__footer {
    padding: calc(30rem / 16) calc(40rem / 16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.cart__summary-item + .cart__summary-item {
    margin-top: calc(20rem/16);
}
.cart__summary__edit {
    position: absolute;
    right:0;
    top:0;
    letter-spacing: 0;
}
.cart__summary .form-control {
    color: var(--color-dark);
}
.cart__summary--grey  {
    background-color: var(--color-middle-grey);
}
.cart__summary--small .cart__summary__header {
    padding-bottom: calc(5rem/16);
    padding-top: calc(20rem/16);
}
.cart__summary--small .cart__summary-heading {
    font-size: calc(18rem/16);
    line-height:1;
}
.cart__summary--grey .cart__summary__body {
    border-color:transparent;
}