:root {
    --main-nav-height: calc(75rem / 16);
    --main-nav-height--xs: calc(45rem / 16);
    --main-nav-bg: #fff;
    --main-nav-color: var(--color-default);
    --main-nav-color-active: var(--color-default);
    --main-nav-item-spacing: calc(18rem / 16);
}
.main-nav {
    box-shadow: 0 0 calc(10rem/16) 0 rgba(0,0,0,0.2);
    height: var(--main-nav-height--xs);
    background-color: var(--main-nav-bg);
    color: var(--main-nav-color);
    display: flex;
    align-items: center;
    position: relative;
    z-index: 999;
    @media screen and (min-width: 768px) {
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-right: calc(190rem/16);
        height: var(--main-nav-height);
        box-shadow: 0 0 calc(20rem/16) 0 rgba(0,0,0,0.1);
    }
}
.main-nav.is-affix {
    position:fixed;
    width: 100%;
}
.main-nav--fixed {
    position: fixed;
    width: 100%;
    top: 0;
}
.main-nav__brand {
    position: absolute;
    z-index: 10;
    display: flex;
    margin-left: auto;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    right: 0;
    top: 0;
    height: calc(160rem/16);
    transform-origin:top;
    padding: calc(31rem / 16) calc(16rem / 16) calc(31rem / 16) calc(31rem / 16);
    @media screen and (max-width: 1600px) and (min-width: 768px) {
        height: calc(144rem/16);
    }
    @media screen and (max-width: 767px) {
        position: relative;
        margin-top: 0;
        transform-origin:center;
        transform:translateY(calc(22rem/16));
        width: calc(130rem/16);
        height: calc(70rem/16);
        padding: 0;
    }
}
.main-nav__brand:before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #FFFFFF;
    box-shadow: 0 calc(10rem/16) calc(10rem/16) calc(-10rem/16) rgba(0,0,0,0.2);
}
.main-nav__brand-img {
    width: calc(142rem/16);
    height: calc(100rem/16);
    z-index: 1;
    margin: 0 auto;
    @media screen and (max-width: 1600px) and (min-width: 768px) {
        width: calc(120rem/16);
        height: calc(90rem/16);
    }
    @media screen and (max-width: 767px) {
        width: calc(85rem/16);
        height: calc(60rem/16);
    }
}
.main-nav__brand-img-text {
    position: absolute;
    width: calc(142rem/16);
    left:0;
    top:0;
    height: calc(100rem/16);
    filter: drop-shadow( 0px 0px calc(10rem/16) rgba(0, 0, 0, .4));
    opacity:1;
    transition: opacity 0.3s ease;
    @media screen and (max-width: 1600px) and (min-width: 768px) {
        width: calc(120rem/16);
    }
    @media screen and (max-width: 767px) {
        width: calc(85rem/16);
        height: calc(60rem/16);
        left:50%;
        transform: translateX(-50%);
    }
}
@media screen and (max-width: 767px) {
    .set-initially-logo-affix--xs .main-nav__brand {
        transform: translateX(0) translateY(0) scale(0.92);
        max-height: calc(80rem/16);
        overflow:hidden;
    }
}
.main-nav__container {
    width: 100%;
    @media screen and (min-width: 768px) {
        max-width: calc(1560rem/16);
        margin: 0 auto;
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
}
.main-nav__collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    @media screen and (min-width: 768px) {
        display: flex!important;
        flex-basis: auto;
        height:100%;
    }
    @media screen and (max-width: 767px) {
        background-color: #fff;
        color:black;
    }
}
.main-nav__nav {
    height: 100%;
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
    }
    @media screen and (max-width: 767px) {
        padding: calc(0rem/16);
    }
}
.main-nav__item {
    letter-spacing: calc(1rem/16);
    line-height: calc(25/20);
    font-size: calc(18rem/16);
    border-bottom: calc(2rem/16) solid transparent;
    position: relative;
    transition: color 0.2s ease;
    padding: 0 calc(31rem/16);
    @media screen and (max-width: 767px) {
        position: static;
        display:inline-flex;
        height:100%;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: calc(10rem/16);
    }
}
.main-nav__nav--right .main-nav__item {
    @media screen and (min-width: 1200px){
        padding: 0 calc(10rem/16);
    }
    @media screen and (min-width: 1600px){
        padding: 0 calc(25rem/16);
    }
}
.main-nav__nav--overlay .main-nav__item {
    display:flex;
    height: auto;
}
.main-nav__item.main-nav__item--shop{
    padding: 0 calc(20rem/16) 0 calc(20rem/16);
    background-color: var(--color-grey);
    @media screen and (min-width: 768px){
        padding: 0 calc(31rem/16);
    }
}
.main-nav__item.main-nav__item--search{
    padding: 0 0 0 calc(15rem/16);
    @media screen and (min-width: 768px){
        padding: 0 0 0 calc(31rem/16);
    }
}
.main-nav__item.main-nav__item--shop + .main-nav__item.main-nav__item--dropdown{
    padding-left: 0;
}
.main-nav__link {
    text-align: left;
    font-size: calc(20rem/16);
    line-height: calc(24rem/16);
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    color: var(--main-nav-color-active);
    opacity: 1;
    border: none;
    background: none;
    transition: opacity 200ms ease-in-out;
    @media screen and (max-width: 767px){
        padding-left: 0;
        padding-right: 0;
    }
}
.main-nav__link:hover,
.main-nav__link:focus-visible{
    @media screen and (min-width: 768px){
        color: var(--color-primary);
    }
}
.main-nav__link.main-nav__link--grey{
    opacity: 0.4;
}
.main-nav__nav--overlay .main-nav__link{
    @media (min-width: 768px) {
        font-size: calc(30rem/16);
        line-height: calc(36/30);
        padding: 0;
    }
}
.main-nav__item.main-nav__item--shop .main-nav__link{
    font-size: calc(18rem/16);
    line-height: calc(16/18);
    font-family: var(--font-default);
}
.main-nav__link .main-nav__link-plus{
    transition: transform 200ms ease-in-out;
    font-size: calc(16rem/16);
}
.main-nav__link[aria-expanded="true"] .main-nav__link-plus{
    transform: rotate(180deg);
}
.main-nav__link[aria-expanded="true"] .main-nav__link-plus:before{
    content: var(--icon-minus);
}

.main-nav__item.main-nav__item--menu{
    padding: 0 0 calc(20rem / 16);
    @media screen and (min-width: 768px){
        padding-bottom: calc(33rem/16);
    }
}
.main-nav__item.main-nav__item--menu + .main-nav__item.main-nav__item--menu{
    position: static;
}
.main-nav__item.main-nav__item--menu + .main-nav__item.main-nav__item--menu > .main-nav__link{
    padding-top: calc(32rem/16);
    position: relative;
}
.main-nav__item.main-nav__item--menu + .main-nav__item.main-nav__item--menu > .main-nav__link:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(30rem/16);
    height: calc(4rem/16);
    background-color: var(--color-grey);
}
.main-nav__item.main-nav__item--menu + .main-nav__item.main-nav__item--menu > .main-nav__link.main-nav__link--grey:before{
    background-color: var(--color-dark-grey);
    opacity: 0.4;
}
.main-nav__link:focus{
    outline: none;
    box-shadow: none;
}
.main-nav__item.show-menu, .main-nav__item.is-open .main-nav__link {
    @media screen and (min-width: 768px) {
        opacity: 1;
    }
}

.main-nav__collapse-icon:before {
    content: var(--icon-arrow-left);
}
.main-nav__collapse-icon {
    transform: rotate(90deg);
    transition: transform 0.2s ease;
}

.main-nav__item--search .main-nav__link-icon{
    @media screen and (min-width: 768px){
        padding-right: 0;
    }
}
.main-nav__item--shop .main-nav__link-icon{
    min-width: calc(29rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        min-width: calc(31rem/16);
    }
}
.main-nav__item--dropdown .main-nav__link{
    font-size: calc(18rem/16);
    line-height: calc(16/18);
}
.main-nav__item--dropdown .main-nav__link-icon{
    @media screen and (min-width: 768px){
        min-width: calc(37rem/16);
    }
}
.dropdown .dropdown-menu,
.main-nav__item--dropdown .dropdown-menu{
    max-height: 50vh;
    overflow-y: scroll;
    z-index: 1000;
}
.main-nav__link-icon {
    padding-right: calc(10rem/16);
    font-size: calc(26rem/16);
    line-height: 1;
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding-right: calc(4rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-right: calc(8rem/16);
        font-size: calc(20rem/16);
    }
}
.main-nav__nav--overlay {
    height:calc(100% - calc(45rem/16));
    overflow-y:scroll;
    transform: translateX(0);
    opacity:0;
    top: var(--main-nav-height--xs);
    width: 100%;
    background-color: #fff;
    display: block;
    visibility: hidden;
    position: fixed;
    z-index: 10;
    margin-left: 0;
    transition: opacity 200ms ease-in-out;
    padding-left: calc(80rem/16);
    padding-top: calc(100rem/16);
    @media screen and (min-width: 768px){
        top: var(--main-nav-height);
        box-shadow: inset 0 0 calc(20rem/16) 0 rgba(0,0,0,0.1);
        bottom: 0;
        height:calc(100% - calc(75rem/16));
    }
    @media screen and (min-width: 1400px){
        padding-left: calc(181rem/16);
        padding-top: calc(158rem/16);
    }
}
.is-open .main-nav__nav--overlay {
    opacity: 1;
    visibility: visible;
}
.main-nav__nav--left {
    padding-right: calc(20rem/16);
    @media screen and (min-width: 768px) {
        position:relative;
    }
}
.main-nav__nav--left .main-nav__link{
    font-family: var(--font-default);
    line-height: calc(16/18);
    letter-spacing: calc(1rem/16);
}
.main-nav__link.dropdown-toggle{
    border-left: solid calc(2rem/16) var(--color-grey);
    border-right: solid calc(2rem/16) var(--color-grey);
    padding: 0 calc(31rem/16);
}
.main-nav__item--has-full-width-second-level {
    @media screen and (min-width: 768px) {
        position: inherit;
    }
}

.main-nav__buttons{
    background-color: var(--color-grey);
    padding: calc(35rem / 16) calc(30rem / 16) calc(80rem / 16) calc(30rem/16);
    margin-top: calc(10rem/16);
}
.main-nav__buttons-title{
    font-size: calc(14rem/16);
    line-height: calc(19/14);
    letter-spacing: calc(1rem/16);
    color: var(--color-default);
    font-family: var(--font-default-bold);
    margin-bottom: calc(10rem/16);
}
.main-nav__nav.main-nav__nav--right{
    display: none;
    @media screen and (min-width: 1200px){
        display: flex;
    }
}
.main-nav__nav.main-nav__nav--right .main-nav__link{
    font-family: var(--font-default);
    text-transform: uppercase;
    font-size: calc(14rem/16);
    line-height: calc(16/18);
    @media screen and (min-width: 1600px){
        font-size: calc(18rem/16);
    }
}
.main-nav__nav.main-nav__nav--right .main-nav__link .main-nav__link-icon{
    @media screen and (max-width: 1599px){
        font-size: calc(16rem/16);
        line-height: 1;
    }
}

.main-nav__overlay-list{
    padding: calc(70rem/16) calc(30rem/16) 0 calc(30rem/16);
    @media screen and (min-width: 768px){
        width: calc(240rem/16);
        position: relative;
        padding: 0;
    }
    @media screen and (min-width: 992px){
        width: calc(320rem/16);
    }
    @media screen and (min-width: 1400px){
        width: calc(492rem/16);
    }
}
.main-nav__overlay-list:before{
    @media screen and (min-width: 768px){
        content: '';
        top: 0;
        right: 0;
        height: 100%;
        width: calc(4rem/16);
        background-color: var(--color-grey);
        position: absolute;
    }
}

/*counter*/
.main-nav__counter{
    position: relative;
    margin-right: calc(15rem/16);
}
.main-nav__counter .main-nav__link-icon{
    padding-right: 0;
}
.main-nav__counter-value{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: calc(-6rem/16);
    background: var(--color-primary);
    border-radius: 50%;
    width: calc(12rem/16);
    height: calc(12rem/16);
    font-size: calc(8rem/16);
    line-height: 1;
    color: #FFFFFF;
    outline: calc(2rem/16) solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}