.shop-img-teaser__embed {
    padding-top: 150%;
    @media screen and (max-width: 767px) {
        padding-top: 80%;
    }
}
.shop-img-teaser {
    cursor:pointer;
    position: relative;
    border: calc(1rem/16) solid #fff;
}
.shop-img-teaser__body {
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    color:#fff;
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
    padding: calc(30rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
        text-align:center;
    }
}
.shop-img-teaser__body:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    opacity: 1;
    transition: opacity 0.5s ease;
    background: linear-gradient(180deg, rgba(47,66,80,0) 0%, var(--color-dark) 100%);
}
.shop-img-teaser__content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align:center;
    line-height: 1.4;
    z-index: 1;
    @media (pointer: fine) and (hover: hover) {
        transform: translateY(calc(20rem/16));
        transition: transform 0.3s ease;
    }
}
.shop-img-teaser__title {
    color:#fff;
    font-size: calc(28rem/16);
    font-family: var(--font-default-bold);
    text-align:center;
    z-index: 1;
    margin-bottom: calc(20rem/16);
    @media (pointer: fine) and (hover: hover) {
        transform: translateY(calc(20rem/16));
        transition: transform 0.3s ease;
    }
    @media screen and (max-width: 1399px) and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.shop-img-teaser__link {
    display:inline-block;
    margin-top: calc(20rem/16);
    justify-content:center;
    align-items:center;
    z-index: 9;
    position: relative;
    @media (pointer: fine) and (hover: hover) {
        opacity:0;
        transform: translateY(calc(20rem/16));
        transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
    }
}
@media (pointer: fine) and (hover: hover) {
    .shop-img-teaser:hover .shop-img-teaser__link {
        opacity: 1;
        transform: translateY(calc(0rem / 16));
        transition: opacity 0.5s ease, transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
    }

    .shop-img-teaser:hover .shop-img-teaser__title, .shop-img-teaser:hover .shop-img-teaser__content {
        transform: translateY(calc(0rem / 16));
    }

    .shop-img-teaser__img {
        transition: transform 0.7s ease;
    }

    .shop-img-teaser:hover .shop-img-teaser__img {
        transform: scale(1.03);
    }

    .shop-img-teaser:hover .shop-img-teaser__body:before {
        opacity: 0.7;
    }
}