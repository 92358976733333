.breadcrumb {
    letter-spacing: 0;
}
.breadcrumb-item {
    @media screen and (max-width: 767px) {
        display: inline;
    }
}
.breadcrumb-item+.breadcrumb-item {
    @media screen and (max-width: 767px) {
        padding-left: calc(4rem/16);
    }
}