.console{
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    width: 100vw;
    display: flex;
    z-index: 900;
    box-shadow: 0 0 calc(20rem/16) 0 rgba(17,20,24,0.1);
    @media screen and (min-width: 768px){
        display: none;
        visibility: hidden;
    }
}
.console__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(19rem/16);
    padding-bottom: calc(16rem/16);
    width: 25vw;
    gap: calc(6rem/16);
}
.console__item:not(:last-child){
    border-right: calc(2rem/16) var(--color-grey) solid;
}
.console__item-title{
    font-size: calc(10rem/16);
    line-height: 1;
    text-transform: uppercase;
}
.console__item-icon{
    font-size: calc(24rem/16);
}