.horizontal-img-text-teaser__body {
    padding: calc(20rem/16);
    height:100%;
    @media (min-width: 992px) {
        padding: calc(20rem/16) calc(1.25rem + ((1vw - 0.62rem) * 4.03225));
        /* Where: * 4.03225 = 100 * padding_difference (40) / viewport_Width_Difference (992) */
    }
    @media (min-width: 1920px) {
        padding: calc(20rem/16) calc(60rem/16);
    }
}