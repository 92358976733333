.title-block__title {
    margin-bottom: calc(10rem/16);
}
.title-block__sub-title + .title-block__title {
    margin-top: calc(10rem/16);
}
.title-block__sub-title  {
    font-family: var(--font-default);
    letter-spacing:unset;
    margin-bottom: calc(10rem/16);
}
.title-block__wysiwyg {
    min-width: auto;
    margin-top: calc(17rem/16);
}
.title-block {
    max-width: calc(1100rem/16);
}
.title-block.text-center {
    margin: 0 auto;
}
.title-block.text-center .title-block__wysiwyg {
    margin: 0 auto;
}
.title-block--text-white .title-block__sub-title, .title-block--text-white .title-block__wysiwyg {
    color:#fff;
}
.title-block--text-dark .title-block__sub-title, .title-block--text-dark .title-block__wysiwyg {
    color: var(--color-dark);
}