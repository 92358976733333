:root {
    --color-primary: #E10018;
    --color-primary-transparent: rgba(255, 0, 24, 0.7);
    --color-secondary: #1D2630;
    --color-text-default: var(--color-default);  /* will be overwritten in dark theme | should not be used for elements with background-color white */
    --color-text-muted: #929CA6;
    --color-default: #111418; /* will be overwritten in dark theme  */
    --color-theme: #fff; /* will be overwritten in dark theme  */

    --color-white: #FFF;
    --color-lightest-grey: #E6EBF0;
    --color-light-grey: rgb(180, 180, 180);

    --color-dark-grey: #6f757d;
    --color-dark: #111418;
    --color-middle-grey: #ced4da;
    --color-grey: #F2F5F8;

    --color-success: #28A745;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;
    --color-grey-dark: var(--color-dark-grey);

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: #000;
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;
    --color-grey-dark-contrast:#fff;

    --color-primary-dark: #940010;
    --color-secondary-dark: #424D56;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #fff;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #faa3af;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;
    --color-dark-grey-light: #888F99;

    --font-default-light: "Museo Sans 300", sans-serif;
    --font-default-bold: "Museo Sans 900", sans-serif;
    --font-default: "Museo Sans 500", sans-serif;

    --font-size-default: 1rem;
    --font-default-weight: 500;
}
