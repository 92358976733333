.blog-teaser {
    margin: calc(64rem /16) 0;
}

.blog-teaser:hover {
    cursor: pointer;
}

.blog-teaser__headline {
    color: var(--color-dark);
    font-family: var(--font-default-bold);
    font-size: calc(30rem/16);
    @media (max-width: 767px) {
        font-size: calc(22rem/16);
    }
    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(45rem / 16);
    }
}

.blog-teaser__head {
    background-color: var(--color-white);
    padding: calc(58rem/16);
    @media (max-width: 767px) {
        padding: calc(20rem/16);
    }
}

.blog-teaser__date {
    color: var(--color-text-default);
    padding: calc(5rem/16) 0;
}

.blog-teaser__text {
    color: var(--color-dark);
    line-height: calc(26/16);
    margin: 0;
}

.blog-teaser__detail {
    color: var(--color-primary);
    font-family: var(--font-default-bold);
}

@media screen and (min-width: 768px) {
    .blog-teaser-animation__img {
        transform: scale(1);
        transition: all 0.9s ease;
        backface-visibility: hidden;
    }

    .blog-teaser:hover .blog-teaser-animation__img {
        transform: scale(1.03);
        transition: transform 0.6s ease;
        overflow: hidden;
    }
}