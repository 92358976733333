.slide {
    padding-top: calc(30rem/16);
    padding-bottom: calc(30rem/16);
}
@media screen and (min-width: 768px) {
    .slide {
        padding-top: calc(140rem/16);
        padding-bottom: calc(140rem/16);
    }
}
.slick-slide > div {
    display: flex;
}