:root {
    --toggler-color: #FFFFFF;
}

.main-nav__toggler {
    width: calc(66rem/16);
    height: calc(45rem/16);
    display:flex;
    position: relative;
    padding-left: calc(20rem/16);
    align-items:center;
    background-color: var(--color-primary);
    border:none;
    justify-content: flex-start;
    @media screen and (min-width: 768px){
        width: calc(150rem/16);
        height: 100%;
        justify-content: space-between;
        padding-left: calc(31rem/16);
        padding-right: calc(27rem/16);
    }
}
.main-nav__toggler:focus{
    outline: none;
}
.main-nav__toggler:hover,
.main-nav__toggler:focus-visible{
    background-color: var(--color-primary-dark);
}
.main-nav__toggler-text{
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: calc(18rem/16);
    line-height: calc(16/18);
    letter-spacing: calc(1rem/16);
    display: none;
    @media screen and (min-width: 768px){
        display: block;
    }
}
.main-nav__toggler-linewrapper{
    position: relative;
    height: calc(11rem/16);
    width: calc(26rem/16);
}
.main-nav__toggler-line {
    position: absolute;
    margin: 0 auto;
    height: calc(2rem/16);
    width: calc(26rem/16);
    background: var(--toggler-color);
    border-radius: calc(2rem/16);
    display: block;
    transition: 0.5s;
    transform-origin: center;
}
.main-nav__toggler-line:nth-child(1) { top: 0; }
.main-nav__toggler-line:nth-child(2) { bottom: 0; }

.is-open  .main-nav__toggler-line:nth-child(1){
    transform: translateY(calc(5rem/16)) rotate(-45deg);
}
.is-open .main-nav__toggler-line:nth-child(2){
    transform: translateY(calc(-5rem/16)) rotate(45deg);
}