.hotspot-area{
    padding: 0 calc(30rem/16);
}
.hotspot-area__filter{
    @media screen and (min-width: 768px){
        display: flex;
        align-items: center;
    }
}
.hotspot-area__filter-title{
    margin-right: calc(40rem/16);
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    line-height: calc(16/14);
    padding: calc(20rem/16) 0;
    @media screen and (min-width: 768px){
        padding: 0;
    }
}

/*hotspots*/
.hotspot-area__hotspot{
    position: absolute;
    width: calc(30rem/16);
    height: calc(30rem/16);
    top: 0;
    left: 0;
    border-radius: 50%;
    box-shadow: 0 calc(4rem/16) calc(10rem/16) 0 rgba(17,20,24,0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    border: none;
    outline: none;
    padding: 0;
    @media screen and (min-width: 768px){
        width: calc(45rem/16);
        height: calc(45rem/16);
    }
}
.hotspot-area__hotspot:focus{
    outline: none;
    box-shadow: 0 calc(4rem/16) calc(10rem/16) 0 rgba(17,20,24,0.15);
}
.hotspot-area__hotspot-content{
    width: calc(25rem/16);
    height: calc(25rem/16);
    color: #FFFFFF;
    background-color: var(--color-dark-grey);
    border-radius: 50%;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(12rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px){
        width: calc(30rem/16);
        height: calc(30rem/16);
        font-size: calc(16rem/16);
    }
}
.hotspot-area__hotspot.has-teaser .hotspot-area__hotspot-content{
    background-color: var(--color-primary);
}
.hotspot-area__hotspot:not(.has-teaser){
    pointer-events: none;
}
.hotspot-area__hotspot.is-active{
    background-color: var(--color-primary);
}
.hotspot-area__hotspot.is-active .hotspot-area__hotspot-content{
    background-color: var(--color-primary);
    opacity: 1;
}
.hotspot-area__hotspot-name{
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #FFFFFF;
    padding: calc(10rem/16);
    font-family: var(--font-default-bold);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    border-radius: calc(4rem/16);
    box-shadow: 0 calc(4rem/16) calc(10rem/16) 0 rgba(17,20,24,0.15);
}
.hotspot-area__hotspot-name:after{
    content: '';
    position: absolute;
    width: calc(15rem/16);
    height: calc(15rem/16);
    background: #FFFFFF;
    transform: rotate(45deg) translateX(-50%);
    top: 85%;
    left: 50%;
}
.hotspot-area__hotspot.is-active .hotspot-area__hotspot-name{
    opacity: 1;
}

.hotspot-area__scroll-mobile{
    @media screen and (max-width: 767px){
        margin-top: calc(20rem/16);
        max-height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}