html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.main-content__body {
    padding-top: calc(80rem/16);
    padding-bottom: calc(150rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(60rem/16);
        padding-bottom: calc(80rem/16);
    }
}
.main {
    overflow-x:hidden;
}
.row--scroll-xs {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    letter-spacing: normal;
    flex-wrap: nowrap;
}
.row--scroll-xs:after {
    content:'';
    position: absolute;
    right:0;
    width: calc(40rem/16);
    background: linear-gradient(to right, rgba(223,223,223,0) 0%,rgba(0,0,0,1) 100%);
}