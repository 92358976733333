.slider {
    margin-bottom:0;
}
.slick-arrow.slick-disabled {
    opacity:0.5;
    pointer-events:none;
}
.slider-arrow--outlines {
    border: 1px solid var(--color-default);
    color: var(--color-default);
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    text-align:center;
    padding:0;
    line-height:1;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.slider-arrow--outlines:hover {
    background-color: var(--color-default);
    color: var(--color-primary);
}
.slider-arrow--small {
    width: calc(46rem/16);
    font-size: calc(18rem/16);
    height: calc(46rem/16);
}
.slider-arrow--outlines + .slider-arrow--outlines {
    border-top:none;
}
.slider {
    clear: both;
}

/* row--same-height */
.slider--same-height .slick-slide {
    display: flex;
    flex-grow: 1;
    height: auto;
    flex-direction: column;
}
/*
 * the .row--same-height selector has to be after
 * .row--same-height__item to slick-arrowincrease the specificity
 * in case of chaining the two selectors
 * (which is needed for nested rows)
 */
.slider--same-height.slick-slider .slick-track {
    display: flex;
    flex-direction: row;
}
.slider--same-height.slick-slider {
    display: block;
}
/* this div is created automatically by slick-slider */
.slider--same-height .slick-slide > div {
    display: flex;
    flex-grow: 1;
    height: auto;
}
.slider--same-height .slick-slide > div > .slider--same-height__item {
    flex-grow: 1;
    height: auto;
}

