.location-teaser {
    color: var(--color-dark);
}
.location-teaser__title {
    color: var(--color-dark);
    font-family: var(--font-default-bold);
    padding-top: calc(46rem/16);
    margin-bottom: calc(10rem/16);
}
.location-teaser__detail {
    color: var(--color-primary);
    font-family: var(--font-default-bold);
}
.location-teaser__info-list {
    display: flex;
    justify-content: space-evenly;
    margin-top: calc(30rem/16);
}
.location-teaser__info-item {
    text-align:center;
}
.location-teaser__info-item + .location-teaser__info-item {
    border-left: 1px solid var(--color-light-grey);
}
.location-teaser__info-item__counter {
    display:block;
    text-align:center;
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    line-height: 1;
}
.location-teaser__content {
    line-height: calc(26/16);
}
.location-teaser__media-btn-wrapper {
    position: absolute;
    background-color:#fff;
    bottom:0;
    right:0;
}
.location-teaser__media-btn {
    padding: calc(14rem/16) calc(19rem/16);
    font-size: calc(14rem/16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height:1;
    background-color: transparent;
    position: relative;
    border:none;
}
.location-teaser__media-btn + .location-teaser__media-btn:before {
    content:'';
    position: absolute;
    left:0;
    width: calc(1rem/16);
    height: calc(20rem/16);
    background-color: var(--color-light-grey);
}
.location-teaser__media-btn__icon {
    color: var(--color-primary);
    margin-right: calc(5rem/16);
}
.location-teaser__facts-list {
    font-size: calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.location-teaser__custom-tabs{
    justify-content: flex-start;

    @media screen and (min-width: 768px){
        justify-content: space-around;
    }
}


/* SEATING */
.seating__tab-item{
    flex-grow: 1;
    flex-basis: 20%;
    text-align: center;
    line-height: 1.2;
}

.seating__tab-item .nav-link{
    border-radius: 0;
    border: 1px solid var(--color-middle-grey);
    position: relative;
    transition: background-color .3s ease, color .3s ease, border .3s ease;
    padding: 0.8rem;

    @media screen and (min-width: 678px){
        padding: 1rem;
        border-right: none;
    }
}

.seating__tab-item:last-child .nav-link{
    border-right: 1px solid var(--color-middle-grey);
}

.nav-tabs .seating__tab-item .nav-link.active, .nav-tabs .seating__tab-item:hover .nav-link{
    background-color: var(--color-primary);
    color: #fff;
    border-color: var(--color-primary);
    height: 100%;
}

.seating__tab-item .nav-link:before{
    display: none;
    border: solid transparent;
    border-top-color: #E2001A;
    border-width: 15px;
    margin-left: -15px;
    top: 100%;
    left: 15px;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    opacity: 0;
    transition: opacity 350ms ease, left 350ms ease;

    @media screen and (min-width: 768px){
        display: block;
    }
}

.nav-tabs .seating__tab-item .active.nav-link:before{
    left: 50%;
    opacity: 1;
}
