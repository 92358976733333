.custom-select__wrapper {
    position:relative;
    width: 100%;
}
.custom-select__wrapper select {
    appearance: none;
}
.custom-select__wrapper select::-ms-expand {
    display: none;
}
.custom-select__wrapper .custom-select__icon {
    position:absolute;
    right:calc(20rem/16);
    font-size:calc(14rem/16);
    pointer-events:none;
    top: 1.6rem;
    transform: translateY(-50%);
}