.video-slider__wrapper {
    position: relative;
}
.video-slider__arrow.arrow-next{
    position: absolute;
    top: 43%;
    right: calc(-85rem/16);
}
.video-slider__arrow.arrow-prev{
    position: absolute;
    top: 43%;
    left: calc(-85rem/16);
}
.video-slider__link{
    cursor: pointer;
}

.video-slider .slick-slide{
    @media screen and (max-width: 767px){
        margin: 0 calc(10rem/16);
        width: calc(280rem/16);
    }
}