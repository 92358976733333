.step-form {
    margin-top: calc(53rem/16);
}
.step-form__legend-item__counter {
    height: calc(54rem/16);
    width: calc(54rem/16);
    color: var(--color-dark-grey);
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: calc(20rem/16);
    background-color: #FFFFFF;
    box-shadow: 0 calc(4rem/16) calc(10rem/16) 0 rgba(17,20,24,0.15);
    text-align:center;
    letter-spacing: calc(1.33rem/16);
    border-radius: 50%;
    transition: transform 0.3s ease;
}
.step-form__legend-item.is-current .step-form__legend-item__counter {
    color: #fff;
    background-color: var(--color-dark);
}
.step-form__legend-item.is-current + .step-form__legend-line {
    border-color: var(--color-default);
}
.step-form__legend-item__title {
    font-size: calc(16rem/16);
    position: absolute;
    transform: translateX(-50%);
    text-align: center;
    left: 50%;
    margin-top: calc(10rem/16);
    line-height: calc(24/16);
    letter-spacing: calc(1rem/16);
    color: var(--color-dark-grey);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
    @media screen and (min-width: 768px){
        white-space: nowrap;
    }
}
.step-form__legend-item.is-current .step-form__legend-item__title{
    color: var(--color-default);
}
.step-form__legend-item {
    position: relative;
}
.step-form__legend-item__counter-wrapper {
    display:grid;
    grid-template-columns: auto 1fr;
    justify-content: center;
    align-items:center;
}
.step-form__legend-line {
    align-self:center;
    border-top: calc(2rem/16) solid var(--color-light-grey);
}
.step-form__legend {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: calc(50rem/16) 1fr calc(50rem/16) 1fr calc(50rem/16) 1fr calc(50rem/16);
    margin-bottom: calc(90rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(60rem/16);
    }
    @media screen and (min-width: 992px){
        padding: 0 calc(69rem/16);
    }
}

.step-form__legend.step-form__columns--group_request {
    grid-template-columns: calc(50rem/16) 1fr calc(50rem/16) 1fr calc(50rem/16);
}

.step-form__steps-wrapper {
    padding-bottom: calc(30rem/16);
    @media screen and (min-width: 768px) {
        padding-bottom: calc(110rem/16);
    }
}

.step-form__legend > div:nth-child(1) { -ms-grid-column: 1; }
.step-form__legend > div:nth-child(2) { -ms-grid-column: 2; }
.step-form__legend > div:nth-child(3) { -ms-grid-column: 3; }
.step-form__legend > div:nth-child(4) { -ms-grid-column: 4; }
.step-form__legend > div:nth-child(5) { -ms-grid-column: 5; }
.step-form__legend > div:nth-child(6) { -ms-grid-column: 6; }
.step-form__legend > div:nth-child(7) { -ms-grid-column: 7; }