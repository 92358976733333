.webcam-item__teaser{
    border-radius: calc(8rem/16);
    overflow: hidden;
    position: relative;
}
.webcam-item__teaser + .webcam-item__teaser{
    margin-top: calc(19rem/16);
}
.webcam-item__teaser:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(17,20,24,0) 0%, rgba(17,20,24,0.8) 100%);
    z-index: 1;
}
.webcam-item__teaser-img{
    transition: transform 200ms cubic-bezier(.7,.2,.6,1);
}
.webcam-item__teaser:hover .webcam-item__teaser-img{
    transform: scale(1.05);
}
.webcam-item__teaser-content{
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: calc(14rem/16) calc(42rem/16);
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.webcam-item__teaser .video__play-btn{
    position: relative;
    transform: translate(0);
    margin-bottom: calc(10rem/16);
    top: 0;
    @media screen and (min-width: 768px){
        margin-bottom: calc(25rem/16);
    }
}
.webcam-item__teaser-title{
    font-size: calc(18rem/16);
    line-height: calc(24/28);
    font-family: var(--font-default-bold);
    color: #FFFFFF;
    margin-bottom: calc(5rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(23rem/16);
    }
}
.webcam-item__teaser-text{
    @media screen and (max-width: 767px){
        font-size: calc(14rem/16);
        line-height: calc(22rem/16);
    }
}