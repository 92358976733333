html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
    line-height: calc(22/16);
    @media screen and (max-width: 767px) {
        line-height: calc(26/16);
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    font-size: calc(35rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    color: var(--color-default);
    /* 42px @ 992px increasing to 60px @ 1920px */
    @media (min-width: 992px) {
        font-size: calc(2.625rem + ((1vw - 0.62rem) * 0.862068));
        /* Where: * 1.9397 = 100 * font_Size_Difference / viewport_Width_Difference */
    }
    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(50rem / 16);
    }
}
h2, .h2 {
    font-size: calc(30rem/16);
    line-height: calc(36/30);
    font-family: var(--font-default-bold);
    color: var(--color-default);
    /* 35px @ 992px increasing to 45px @ 1920px */
    @media (min-width: 992px) {
        font-size: calc(2.1875rem + ((1vw - 0.62rem) * 1.0776));
        /* Where: * 1.0776 = 100 * font_Size_Difference (10) ( / viewport_Width_Difference (928) */
    }
    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(45rem / 16);
    }
}
h3, .h3 {
    font-size: calc(20rem/16);
    line-height: calc(24/20);
    color: var(--color-default);
    font-family: var(--font-default);

    /* 24px @ 992px increasing to 30px @ 1920px */
    @media (min-width: 992px) {
        font-size: calc(1.5rem + ((1vw - 0.62rem) * 0.6466));
        /* Where: * 0.6466 = 100 * font_Size_Difference / viewport_Width_Difference */
    }

    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(30rem / 16);
    }
}
h4, .h4 {
    font-size: calc(18rem/16);
    line-height: calc(21/18);
    color: var(--color-default);
    font-family: var(--font-default);

    /* 18.5px @ 992px increasing to 20px @ 1920px */
    @media (min-width: 992px) {
        font-size: calc(1.15625rem + ((1vw - 0.62rem) * 0.1616));
        /* Where: * 0.1616 = 100 * font_Size_Difference / viewport_Width_Difference */
    }
    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(20rem / 16);
    }
}
h5, .h5 {
    font-size: 1rem;
    line-height: calc(19/16);
    font-family: var(--font-default);
    color: var(--color-default);

    /* 16.5px @ 992px increasing to 18px @ 1920px */
    @media (min-width: 992px) {
        font-size: calc(1.03125rem + ((1vw - 0.62rem) * 0.1616));
        /* Where: * 0.1616 = 100 * font_Size_Difference / viewport_Width_Difference */
    }

    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(18rem / 16);
    }
}
h6, .h6 {
    font-size: calc(14rem/16);
    color: var(--color-default);
    font-family: var(--font-default);
    line-height: calc(16/14);
    /* 14.5px @ 992px increasing to 16px @ 1920px */
    @media (min-width: 992px) {
        font-size: calc(0.90625rem + ((1vw - 0.62rem) * 0.1616));
        /* Where: * 0.1616 = 100 * font_Size_Difference / viewport_Width_Difference */
    }

    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: 1rem;
    }
}