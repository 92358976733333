.anchor-nav__container{
    position: relative;
    height: calc(90rem/16);
    @media screen and (min-width: 768px) {
        height: calc(55rem / 16);
    }
}
.anchor-nav__container.anchor-nav__container-only-mobile{
    @media screen and (min-width: 768px){
        height: 0;
    }
}
.anchor-nav{
    position: fixed;
    top: calc(45rem/16);
    left: 0;
    width: 100%;
    height: calc(90rem/16);
    background-color: #FFFFFF;
    box-shadow: 0 calc(4rem/16) calc(10rem/16) 0 rgba(17,20,24,0.15);
    z-index: 998;
    font-size: calc(18rem/16);
    line-height: calc(16/18);
    letter-spacing: calc(1rem/16);
    color: var(--color-dark-grey);
    @media screen and (min-width: 768px){
        height: calc(55rem/16);
        top: calc(75rem/16);
    }
}
.anchor-nav.anchor-nav__only-mobile{
    @media screen and (min-width: 768px){
        display: none;
        visibility: hidden;
    }
}
.anchor-nav__content{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: calc(90rem/16);
    @media screen and (min-width: 768px){
        justify-content: start;
        align-items: center;
        height: calc(55rem/16);
    }
    @media screen and (min-width: 1200px){
        justify-content: center;
    }
}
.list-inline-item.anchor-nav__item:not(:last-child){
    @media screen and (min-width: 768px){
        margin-right: calc(27rem/16);
    }
    @media screen and (min-width: 1400px){
        margin-right: calc(77rem/16);
    }
}
.anchor-nav__list.anchor-nav__list--last-primary .list-inline-item.anchor-nav__item:last-child .nav-link{
    padding-left: calc(30rem/16);
    padding-right: calc(30rem/16);
    background-color: var(--color-primary);
    color: #FFFFFF;
    @media screen and (max-width: 767px){
        margin-top: calc(15rem/16);
    }
}
.anchor-nav__list.anchor-nav__list--last-primary .list-inline-item.anchor-nav__item:last-child .nav-link.active{
    color: #FFFFFF;
}
.anchor-nav__description{
    margin-right: calc(20rem/16);
    padding: calc(19rem/16) 0;
    @media screen and (min-width: 768px){
        padding: 0;
        margin-right: calc(27rem/16);
    }
    @media screen and (min-width: 1400px){
        margin-right: calc(77rem/16);
    }
}
.anchor-nav__item .nav-link{
    padding: calc(19rem/16) 0;
    position: relative;
}
.anchor-nav__item .nav-link.active{
    color: var(--color-default);
}
.anchor-nav__item .nav-link:before{
    transition: transform 200ms cubic-bezier(.7,.2,.6,1);
    transform-origin: center;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(3rem/16);
    width: 100%;
    background: var(--color-primary);
    transform: scaleX(0);
}
.anchor-nav__item .nav-link.active:before{
    transform: scaleX(1);
}
.anchor-nav__button{
    margin-left: calc(79rem/16);
    border-radius: 0;
    padding: calc(19rem/16) calc(30rem/16);
    display: none;
}

/*dropdown*/
.anchor-nav__dropdown-button{
    padding: calc(19rem/16) 0;
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    gap: calc(19rem/16);
    color: var(--color-default);
    @media screen and (min-width: 768px){
        display: none;
    }
}
.anchor-nav__dropdown-button:focus{
    outline: none;
}
.anchor-nav__dropdown-button-icon{
    font-size: calc(10rem/16);
}
.anchor-nav__list{
    @media screen and (max-width: 767px){
        position: absolute;
        top: 100%;
        background: #FFF;
        left: 0;
        padding: calc(20rem/16);
        box-shadow: 0 calc(4rem/16) calc(10rem/16) 0 rgba(17,20,24,0.15);
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 200ms ease-in-out;
    }
}
.anchor-nav__list.is-active{
    visibility: visible;
    opacity: 1;
}
.anchor-nav__list > .list-inline-item{
    @media screen and (max-width: 767px){
        display: block;
        width: fit-content;
        margin-left: auto;
    }
}