.accordion-icon--change {
    color: var(--color-primary);
    transition: all 0.2s ease;
    transform:rotate(-90deg);
    @media screen and (max-width: 767px) {
        font-size:calc(16rem/16);
    }
}
.accordion-icon--change:before {
    content: var(--icon-arrow-right);
}
.collapsed .accordion-icon--change {
    transform:rotate(90deg);
}
.accordion__toggle-icon {
    color: var(--color-default);
    transition: all 0.2s ease;
    @media screen and (max-width: 767px) {
        font-size:calc(16rem/16);
    }
}
.collapsed .accordion__toggle-icon {
    transform:rotate(180deg);
}
.accordion__title {
    text-transform: uppercase;
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    padding: calc(10rem/16) calc(15rem/16);
}
.accordion + .accordion {
    margin-top: calc(50rem/16);
}
.accordion-card__header {
    padding: calc(10rem/16) calc(15rem/16);
    border-top: 1px solid var(--color-text-default);
}
.accordion {
    border-bottom: 1px solid var(--color-text-default);
}
.accordion-card__body {
    padding: 0 calc(15rem/16) calc(10rem/16);
}