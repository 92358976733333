.facilities-item__container{
    padding: calc(16rem/16) calc(20rem/16);
    background: #FFFFFF;
    border-radius: calc(8rem/16);
}
.facilities-item__container + .facilities-item__container{
    margin-top: calc(10rem/16);
}
.facilities-item__title{
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    line-height: calc(19/14);
    margin-bottom: calc(11rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}
.facilities-item__row{
    display: flex;
    gap: calc(9rem/16);
}
.facilities-item__row + .facilities-item__row{
    margin-top: calc(22rem/16);
}
.facilities-item__state{
    background-color: var(--color-success);
    border-radius: 50%;
    font-size: calc(6rem/16);
    color: #FFFFFF;
    width: calc(16rem/16);
    height: calc(16rem/16);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    @media screen and (min-width: 768px){
        font-size: calc(10rem/16);
        width: calc(22rem/16);
        height: calc(22rem/16);
    }
}
.facilities-item__state.facilities-item__state--closed{
    background-color: var(--color-danger);
}
.facilities-item__type{
    font-size: calc(16rem/16);
    line-height: 1;
    flex-shrink: 0;
    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
    }
}
.facilities-item__name{
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}
.facilities-item__time{
    font-size: calc(12rem/16);
    line-height: calc(16/12);
    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}