.teaser-slider-lg__slider-container{
    margin-top: calc(30rem/16);
    @media screen and (min-width: 768px){
        width: calc(66.6666vw - calc(32rem/16));
        margin-top: 0;
    }
}

.teaser-slider-lg__slider .slick-dots{
    display: none;
    height: 0;
    visibility: hidden;
}
.teaser-slider-lg__slider .slick-slide{
    width: calc(298rem/16);
    margin: 0 calc(8rem/16);
    @media screen and (min-width: 768px){
        width: calc(550rem/16);
        margin: 0 calc(20rem/16);
    }
    @media screen and (min-width: 1200px){
        width: calc(761rem/16);
    }
}
.teaser-slider-lg__slider .slick-list{
    @media screen and (min-width: 768px){
        margin: 0 calc(-20rem/16);
    }
}

.teaser-slider-lg__arrow.arrow-prev{
    right: 84%;
    top: 45%;
    @media screen and (min-width: 768px){
        left: 3%;
        top: 40%;
    }
}
.teaser-slider-lg__arrow.arrow-next{
    right: 9%;
    top: 45%;
    @media screen and (min-width: 768px){
        right: 7%;
        top: 40%;
    }
    @media screen and (min-width: 1600px){
        right: -11%;
    }
}