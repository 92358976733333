.content-block + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(60rem/16);

    @media (min-width: 768px) {
        margin-top: calc(4.25rem + ((1vw - 0.62rem) * 5));
        /* Where: * 7.8125 = 100 * margin_difference (90) / viewport_Width_Difference (1152) */
    }
    @media (min-width: 1920px) {
        margin-top: calc(120rem/16);
    }
}
.content-block + .content-block--xs,
.content-block--xs + .content-block,
.content-block--xs + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block--xs {
    @media (max-width: 768px) {
        margin-top: calc(60rem/16);
    }
}

.content-block.content-block--less-spacing + .content-block,
.content-block.content-block--less-spacing + .pimcore_area_content > .content-block {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(70rem/16);
    }
}
/* this should remove the space between this (last) element and the footer */
.main-content__body > .content-block:last-child.content-block--no-footer-margin,
.main-content__body > .pimcore_area_content:last-child .content-block.content-block--no-footer-margin {
    margin-bottom: calc(-150rem/16);  /* length of main-content__body bottom padding */
    @media screen and (max-width: 767px) {
        margin-bottom: calc(-80rem/16);
    }
}
.pimcore_area_portal-map.pimcore_area_content + .pimcore_area_content .content-block {
    margin-top: calc(20rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(0rem/16);
    }
}