.nav-tabs .nav-item > .nav-link{
    font-size: calc(16rem/16);
    line-height: calc(19/20);
    color: var(--color-text-muted);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        line-height: calc(19/20);
    }
}
.nav-tabs .nav-item > .nav-link.active{
    color: var(--color-default);
}
.nav-tabs.nav-tabs--flex{
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-wrap: nowrap;
    overflow-x: auto;
}
.nav-tabs.nav-tabs--flex .nav-item{
    flex: 1;
    list-style-type: none;
    text-align: center;
}
.nav-tabs.nav-tabs--flex .nav-item > .nav-link{
    border-bottom: calc(3rem/16) solid #FFFFFF;
}
.nav-tabs.nav-tabs--flex .nav-item > .nav-link.active{
    border-color: var(--color-default);
}

.nav-tabs .nav-tabs__text-sm{
    @media screen and (max-width: 767px){
        font-size: calc(12rem/16);
    }
}