.video-js {
    cursor:pointer;
}
.video-js .vjs-big-play-button, .video__play-btn {
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    transform: translateY(-50%);
    width: calc(40rem/16);
    height: calc(40rem/16);
    border-radius: 50%;
    display:flex;
    justify-content:center;
    align-items:center;
    pointer-events:none;
    background-color: #FFFFFF;
    border: none;
    box-shadow: 0 calc(4rem/16) calc(10rem/16) 0 rgba(17,20,24,0.15);
    transition: background-color 200ms ease-in-out;
    @media screen and (min-width: 768px){
        width: calc(80rem/16);
        height: calc(80rem/16);
    }
}
.video__play-btn.video__play-btn--sm{
    width: calc(30rem/16);
    height: calc(30rem/16);
    @media screen and (min-width: 768px){
        width: calc(45rem/16);
        height: calc(45rem/16);
    }
}
.video-js:hover .vjs-big-play-button,
.video-slider__link:hover .video__play-btn{
    background-color: var(--color-primary);
}
.vjs-has-started .vjs-big-play-button {
    opacity:0;
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before, .video__play-btn-icon {
    content: var(--icon-play);
    font-family: 'iconfont';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(17rem / 16);
    height: auto;
    color: var(--color-default);
    width: auto;
    transition: color 400ms ease;
    margin-left: calc(2rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(30rem / 16);
    }
}
.video__play-btn.video__play-btn--sm .video__play-btn-icon{
    font-size: calc(13rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}
.video__play-btn {
    background-color: #FFFFFF;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}