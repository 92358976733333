.form-bordered__form {
    max-width: calc(740rem/16);
    margin: 0 auto;
    position: relative;
}
.form-bordered__form__inner {
    padding: calc(40rem / 16) calc(55rem / 16) calc(70rem / 16);
    border-left: 1px solid var(--color-text-default);
    border-right: 1px solid var(--color-text-default);
    border-bottom: 1px solid var(--color-text-default);
    margin-top: calc(-21rem / 16);
    background-color: var(--color-theme);
    @media screen and (max-width: 767px) {
        margin-top: calc(-18rem / 16);
        padding: calc(20rem / 16) calc(20rem / 16) calc(70rem / 16);
    }
}
.form-bordered__title {
    color: var(--color-default);
    grid-template-columns:  1fr auto 1fr;
    position: relative;
    grid-gap: calc(30rem/16);
    align-items: center;
    justify-content: center;
    display: grid;
    width: 100%;
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
        line-height:1;
        z-index: 99;
    }
}
.form-bordered__title:before, .form-bordered__title:after {
    content:'';
    margin-top:1px;
    border-top: 1px solid var(--color-text-default);
}
.form-bordered__submit {
    transform: translateY(-50%);
    background-color: var(--color-theme);
    color: var(--color-default);
}
.form-bordered__content {
    @media screen and (min-width: 768px) {
        max-width: 95vw;
        margin: 0 auto;
        border: 1px solid var(--color-text-default);
        padding-top: calc(150rem/16);
        padding-bottom: calc(80rem/16);
        margin-top: calc(-100rem/16);
    }
}