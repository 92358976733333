.img-teaser {
    position: relative;
    overflow: hidden;
}
.img-teaser:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(17,20,24,0) 0%, rgba(17,20,24,0.8) 90%);
    z-index: 1;
}

.img-teaser:hover {
    cursor: pointer;
}

.img-teaser__embed::before {
    padding-top: calc( 656 / 492 * 100%);
}

.img-teaser__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: calc(24rem/16);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1;
    text-align: center;
    @media screen and (min-width: 768px){
        padding: calc(46rem/16);
    }
}

.img-teaser__title {
    color: #FFFFFF;
    margin-bottom: 0;
    font-family: var(--font-default-bold), sans-serif;
    font-size: calc(20rem/16);
    line-height: calc(26/20);
    @media screen and (min-width: 768px){
        font-size: calc(26rem/16);
        line-height: 1;
    }
}

@media screen and (min-width: 768px) {
    .img-teaser:hover .img-teaser__img {
        transform: scale(1.03);
        transition: transform 0.6s ease;
    }

    .img-teaser__img {
        transform: scale(1);
        transition: all 0.9s ease;
        backface-visibility: hidden;
    }
}
