.img-text-teaser__embed:before {
    padding-top: calc( 277 / 370 * 100%);
}
.img-text-teaser {
    background-color:#fff;
    cursor:pointer;
    border: calc(1rem/16) solid var(--color-lightest-grey);
}
.img-text-teaser__img {
    transition: transform 0.3s ease;
}
.img-text-teaser__title {
    color: var(--color-dark);
    font-family: var(--font-default-bold);
    font-size: calc(22rem/16);
    margin-bottom: calc(10rem/16);
    transition: transform 0.3s ease, color 0.2s ease;
}
.img-text-teaser__body {
    padding: calc(20rem/16);
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (min-width: 992px) {
        padding: calc(1.25rem + ((1vw - 0.62rem) * 1.5121));
        /* Where: * 1.5121 = 100 * padding_difference (15) / viewport_Width_Difference (992) */
    }
    @media (min-width: 1920px) {
        padding: calc(35rem/16);
    }
}
.img-text-teaser__text {
    line-height: calc(25/16);
    color: var(--color-light-grey);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.img-text-teaser__media {
    overflow:hidden;
    background: radial-gradient(circle, #FFFFFF 0%, #E6EBF0 100%);
    position: relative;
}
.img-text-teaser__media:after {
    content:'';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, #cdcdcd 0%, var(--color-secondary) 100%);
    opacity:0.1;
    transition: opacity 0.3s ease;
}
.img-text-teaser__img--frame {
    padding: calc(20rem/16);
}
.img-text-teaser:hover .img-text-teaser__img {
    transform: scale(1.04);
}
.img-text-teaser:hover .img-text-teaser__title {
    transform: translateY(-5px);
    color: var(--color-primary);
}
.img-text-teaser:hover .img-text-teaser__media:after {
    opacity:0;
}