.cart-item {
    border: calc(1rem/16) solid var(--color-light-grey);
    background-color: var(--color-white);
    color: var(--color-dark);
}
.cart-item__header {
    padding: calc(20rem/16);
}
.cart-item h2 {
    color: var(--color-dark);
}
.cart-item__body {
    border-top: calc(1rem/16) solid var(--color-light-grey);
    padding: calc(20rem/16);
}
.cart-item__title {
    font-size: calc(20rem/16);
    line-height:1;
    margin-bottom: calc(10rem/16);
    font-family: var(--font-default-bold);
    color: var(--color-dark);
}
.cart-item__subtitle {
    font-size: 1rem;
    color: var(--color-dark);
}
.cart-item__date {
    line-height: 1;
}
.cart-item__price {
    color: var(--color-secondary);
    font-family: var(--font-default-bold);
    font-size: calc(24rem/16);
    line-height:1;
}
.cart-item__delete {
    letter-spacing: 0;
    display: flex;
    justify-content:center;
    align-items:center;
}
.cart-item + .cart-item {
    margin-top: 2.5rem;
}
.cart-item__age-group {
    line-height:1;
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
}
.cart-item__price-info {
    font-size: calc(12rem/16);
    font-family: var(--font-default);
    display: block;
    line-height:1;
}
.cart-item__info {
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 50%;
    padding: calc(2rem/16);
    font-size: calc(13rem/16);
    font-family: var(--font-default-bold);
    width: calc(18rem/16);
    height: calc(18rem/16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0;
    line-height:1;
    border: none;
    box-shadow:none;
}