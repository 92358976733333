.form-step {
    display: none;
}
.form-step.is-current {
    display: block;
}

.form-step__anchor{
    display: block;
    position: relative;
    top: calc(-150rem/16);
    visibility: hidden;
}