.special-link-list {
    display:flex;
    justify-content: space-between;
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        width: 100%;
        overflow-x: visible;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        letter-spacing: normal;
        flex-wrap: nowrap;
    }
}
.special-link-list.active:before {
    @media screen and (max-width: 767px) {
        opacity: 0;
    }
}
.special-link-list:before {
    @media screen and (max-width: 767px) {
        content:var(--icon-swipe);
        display:block;
        font-family: 'iconfont';
        speak: none;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        text-rendering: auto;
        transform: translate(0);
        font-size: 30px;
        position: absolute;
        right:calc(35rem/16);
        color: var(--color-text-default);
        z-index: 3;
        background: rgba(255,255,255,0.8);
        padding: 10px;
        border-radius: 50%;
        pointer-events: none;
        transition: opacity 0.3s ease;
    }
}
.special-link-list:hover .special-link-list:before {
    opacity:0;
}
.special-link-list__item {
    text-align:center;
    letter-spacing: calc(1rem/16);
    color: var(--color-text-default);
    margin-bottom: calc(20rem/16);
    transition: color 0.3s ease;
    padding-left: calc(4rem/16);
    padding-right: calc(4rem/16);
    font-size: calc(18rem/16);

    /* 18.5px @ 992px increasing to 20px @ 1920px */
    @media (min-width: 992px) {
        font-size: calc(1.15625rem + ((1vw - 0.62rem) * 0.1616));
        /* Where: * 0.1616 = 100 * font_Size_Difference / viewport_Width_Difference */
    }
    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(20rem / 16);
    }
}
.special-link-list__item:hover {
    color: var(--color-default);
}
.special-link-list__item.active {
    color: var(--color-default);
    position: relative;
    border-bottom: calc(1rem/16) solid var(--color-default);
}
.special-link-list__icon {
    font-size: calc(28rem/16);
    display:block;
}
.special-link-list__item.active:after {
    border: solid transparent;
    border-top-color: var(--color-theme);
    border-width: calc(7rem/16);
    margin-left: calc(-7rem/16);
    top: 99%;
    left: 50%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    opacity: 1;
}
.special-link-list__item.active:before {
    border: solid transparent;
    border-top-color: var(--color-default);
    border-width: calc(8rem/16);
    margin-left: calc(-8rem/16);
    top: 100%;
    left: 50%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    opacity: 1;
}
.special-link-list__icon-wrapper {
    position: relative;
    width: calc(50rem/16);
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.special-link-list__status {
    position: absolute;
    bottom:calc(-5rem/16);
    right:0;
}