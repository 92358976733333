@font-face {
    font-family: 'Museo Sans 300';
    src: url('/static/fonts/museosans300-webfont.woff2') format('woff2'),
    url('/static/fonts/museosans300-webfont.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 500';
    src: url('/static/fonts/museosans500-webfont.woff2') format('woff2'),
    url('/static/fonts/museosans500-webfont.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Museo Sans 900';
    src: url('/static/fonts/museosans900-webfont.woff2') format('woff2'),
    url('/static/fonts/museosans900-webfont.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}