.special-teaser__embed:before {
    padding-top: calc( 728 / 1920 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: calc( 678 / 375 * 100%);
    }
}
.special-teaser__background:after {
    content:'';
    position: absolute;
    width:100%;
    height:100%;
    left:0;
    transform: scaleY(-1);
    background: linear-gradient(180deg, rgba(17,20,24,0) 0%, rgba(17,20,24,0.8) 100%);
    top:-1px;
}
.special-teaser__background {
    position: relative;
    padding-top: calc( 728 / 1920 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: calc( 678 / 375 * 100%);
    }
}
.special-teaser__content {
    position: absolute;
    top:0;
    width:100%;
    height:100%;
    left:0;
}
.special-teaser__item {
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
}
.special-teaser__col--border + .special-teaser__col--border {
    border-top: solid calc(1rem/16) rgba(255,255,255,0.4);
    @media screen and (min-width: 768px){
        border-top: none;
        border-left: solid calc(1rem/16) rgba(255,255,255,0.4);
    }
}
.special-teaser__title {
    font-family: var(--font-default-bold);
    margin-bottom: calc(20rem/16);
    color: #FFFFFF;
    font-size: calc(45rem/16);
    line-height: calc(54/45);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(10rem/16);
        font-size: calc(25rem/16);
        line-height: calc(30/25);
    }
}
.special-teaser__sub-title {
    font-size: calc(20rem/16);
    color: #FFFFFF;
    margin-bottom: calc(0rem/16);
    line-height: 1.2;
    transform: translateY(calc(-20rem/16));
    transition: opacity 0.3s ease, transform 0.3s ease;
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        transform: translateY(0);
    }
}
.special-teaser__background-item {
    opacity: 0;
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    transition: opacity 0.8s cubic-bezier(.7,.2,.6,1);
    //visibility: hidden;
    //display:none;
}
.special-teaser__background-item.active {
    opacity:1;
    //visibility: visible;
    //display:block;
}
.special-teaser__sub-title, .special-teaser__button {
    @media screen and (min-width: 768px){
        opacity:0;
    }
}
.special-teaser__button {
    transform: translateY(calc(20rem/16));
    transition: opacity 0.3s ease, transform 0.3s ease;
    @media screen and (max-width: 767px){
        transform: translateY(0);
    }
}
.special-teaser__item {
    padding: calc(20rem/16);
    opacity:0.4;
    text-align:center;
    transition: opacity 0.3s ease;
}
.special-teaser__item.initial-state {
    opacity:1;
}
.special-teaser__item-container{
    @media screen and (min-width: 768px){
        max-width: calc(380rem/16);
    }
}
.special-teaser__item.active .special-teaser__sub-title, .special-teaser__item.active .special-teaser__button, .special-teaser__item.active {
    opacity:1;
    transform: translateY(0);
}
.special-teaser__icon {
    display: block;
    text-align: center;
    font-size: calc(35rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}