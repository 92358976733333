.live-info-header {
    border-bottom: 1px solid var(--color-text-default);
}
.live-info-header__item {
    height: calc(333rem/16);
    width:100%;
    background-size: cover;
}
.live-info-header__item:before {
    content:'';
    position: absolute;
    top:0;
    width:100%;
    height:100%;
    background-color: #111418;
    opacity: 0.84;
    transition: opacity 0.5s ease;
}
.live-info-header__item--active:before {
    opacity:0;
}
.live-info-header__item__inner {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor:pointer;
    @media screen and (max-width: 767px) {
        justify-content: space-around;
        padding: calc(20rem/16) 0;
    }
}
.live-info-header__item--active {
    padding: calc(40rem/16) calc(40rem/16) 0 calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}
.live-info-header__item--active .live-info-header__item__inner {
    border: 1px solid var(--color-text-default);
    border-bottom: none;
    @media screen and (max-width: 767px) {
        border: none;
    }
}
.live-info-header__item:hover:before {
   opacity:0.2;
}
.live-info-header__item__title {
    color: #FFFFFF;
    font-family: var(--font-default-bold);
    text-align: center;
    letter-spacing: 0;
    line-height: 1;
    z-index: 1;
    margin-top: calc(28rem/16);
    text-shadow: calc(2rem/16) calc(2rem/16) calc(10rem/16) rgba(0, 0, 0, 0.59);
    font-size: calc(20rem/16);
    @media (max-width: 767px) {
        transform: rotate(-90deg);
    }
    /* 20 @ 992px increasing to 24px @ 1920px */
    @media (min-width: 992px) {
        letter-spacing: calc(3rem/16);
        font-size: calc(1.25rem + ((1vw - 0.62rem) * 0.43103448));
        /* Where: * 0.43103448 = 100 * font_Size_Difference / viewport_Width_Difference (928) */
    }
    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(24rem / 16);
    }
}
.live-info-header__item--active .live-info-header__item__title {
    font-size: calc(50rem/16);
    letter-spacing: calc(3rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
        transform: rotate(0);
    }
}
.live-info-header__item__badge {
    width: calc(60rem/16);
    height: calc(60rem/16);
    background-color: transparent;
    border: 1px solid var(--color-default);
    border-radius: 50%;
    display:block;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
    @media screen and (max-width: 767px) {
        width: calc(40rem/16);
        height: calc(40rem/16);
    }
}
.live-info-header__item:hover .live-info-header__item__badge {
    animation: pulse 2s infinite;
}
.live-info-header__item__badge:before {
    content:'';
    position: absolute;
    left:0;
    width: calc(30rem/16);
    height: calc(30rem/16);
    background-color: #fff;
    border: calc(2rem/16) solid #fff;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    top: 50%;
    transform-origin: top;
    transition: transform 0.3s ease;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
        width: calc(20rem/16);
        height: calc(20rem/16);
    }
}
.live-info-header__item__badge:after {
    content:'';
    position: absolute;
    height: calc(8rem/16);
    width: calc(8rem/16);
    border-radius: 50%;
    background-color: var(--color-primary);
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.3s ease;
}
.live-info-header__item:hover .live-info-header__item__badge:after, .live-info-header__item--active .live-info-header__item__badge:after {
    background-color: #fff;
}
.live-info-header__item:hover .live-info-header__item__badge:before, .live-info-header__item--active .live-info-header__item__badge:before {
    transform: scale(1.4) translateY(-50%);
    background-color: var(--color-primary);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgb(255, 255, 255);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}