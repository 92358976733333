.status {
    width: calc(30rem/16);
    height: calc(30rem/16);
    border: calc(2rem/16) solid #fff;
    color: #fff;
    border-radius: 50%;
    font-size: calc(13rem/16);
    display: flex;
    justify-content:center;
    align-items: center;
    text-align:center;
}
.status--small {
    width: calc(18rem/16);
    height: calc(18rem/16);
    border: calc(1rem/16) solid #fff;
    font-size: calc(8rem/16);
}
.status-success {
    background-color: var(--color-success);
}
.status:before {
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0);
    transform: translate(0);
}
.status-success:before {
    content: var(--icon-check);
}
.status-danger {
    background-color: var(--color-danger);
}
.status-danger:before {
    content: var(--icon-close);
}