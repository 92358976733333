@media screen and (min-width: 768px) {
    .img-animation__img-wrapper:hover .img-animation__img {
        transform: scale(1.03);
        transition: transform 0.6s ease;
    }
    .img-animation__img {
        transform: scale(1);
        transition: all 0.9s ease;
        backface-visibility: hidden;
    }
    .img-animation__img-wrapper:after {
        content:'';
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color: var(--color-dark);
        opacity: 0;
        transition: opacity 0.6s ease;
    }
    .img-animation__img-wrapper:hover:after {
        opacity:0.3;
    }
    .img-animation__zoom-icon {
        opacity:0;
        transform: scale(0);
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 2;
        color: #fff;
        font-size: calc(50rem/16);
        display: flex;
        justify-content: center;
        align-items: center;
        text-shadow: calc(2rem/16) calc(2rem/16) calc(10rem/16) rgba(0, 0, 0, 0.59);
        transition: opacity 0.4s ease, transform 0.3s ease;
    }
    .img-animation__img-wrapper:hover .img-animation__zoom-icon {
        opacity:1;
        transform: scale(1);
    }
}
.img-animation__zoom-icon {
    @media screen and (max-width: 767px) {
        display:none;
    }
}