.image-slider__wrapper {
    position: relative;
}
.image-slider__wrapper .slick-slide{
    margin: 0 calc(10rem/16);
    @media screen and (max-width: 767px){
        width: calc(298rem/16);
    }
}
.image-slider__wrapper .slick-list{
    margin: 0 calc(-10rem/16);
    @media screen and (max-width: 767px){
        margin-left: 0;
        margin-right: calc(-20rem/16);
    }
}
.image-slider__arrow.arrow-next{
    top: 45%;
    right: calc(-85rem/16);
    @media screen and (min-width: 768px) and (max-width: 1599px) {
        right: calc(20rem/16);
    }
}
.image-slider__arrow.arrow-prev{
    top: 45%;
    left: calc(-85rem/16);
    @media screen and (min-width: 768px) and (max-width: 1599px) {
        left: calc(20rem/16);
    }
}

.image-slider__embed::before {
    padding-top: calc( 769 / 1026 * 100%);
}
