.teaser-slider-lg-teaser{
    position: relative;
}
.teaser-slider-lg-teaser .teaser-slider-lg-teaser__img{
    transition: transform 200ms ease-in-out;
}
.teaser-slider-lg-teaser:hover .teaser-slider-lg-teaser__img{
    transform: scale(1.03);
}
.teaser-slider-lg-teaser:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(17,20,24,0) 0%, rgba(17,20,24,0.8) 100%);
    width: 100%;
    height: 100%;
    z-index: 1;
}
.teaser-slider-lg-teaser__content{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: calc(13rem/16);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media screen and (min-width: 768px){
        padding: calc(30rem/16);
    }
}
.teaser-slider-lg-teaser__title{
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    line-height: calc(26/20);
    color: #FFFFFF;
    @media screen and (min-width: 768px){
        font-size: calc(26rem/16);
        line-height: 1;
    }
}