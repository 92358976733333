.payone-cc-message-wrap {
    display: none;
}

.payone-cc-icon {
    margin: 5px;
    padding: 5px;
    width: auto;
    height: 40px;
    border: 2px solid transparent;
}

.payone-cc-icon--clickable:hover {
    cursor: pointer;
}

.payone-cc-icon--selected {
    border-color: #0078AB;
}

.payone-input-mock {
    border: 2px solid #0078AB;
    padding: 5px 15px;
}

.payone-input-mock iframe {
    height: 50px;
    width: 100%;
    vertical-align: middle;
}