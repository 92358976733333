.fullwidth-teaser{
    background-color: #FFFFFF;
    color: var(--color-default);
    border-radius: calc(8rem/16);
    padding: calc(15rem/16) calc(15rem/16);
    @media screen and (min-width: 768px){
        padding: calc(54rem/16) calc(60rem/16);
    }
}
.fullwidth-teaser__title{
    font-family: var(--font-default-bold);
    margin-bottom: calc(31rem/16);
}
.fullwidth-teaser__feature{
    display: flex;
    align-items: center;
    font-size: calc(16rem/16);
    line-height: calc(19/20);
    @media screen and (min-width: 1200px){
        font-size: calc(20rem/16);
    }
}
.fullwidth-teaser__feature-icon{
    font-size: calc(20rem/16);
    margin-right: calc(10rem/16);
    @media screen and (min-width: 1200px){
        font-size: calc(24rem/16);
    }
}
.fullwidth-teaser__info{
    font-size: calc(16rem/16);
    line-height: calc(26/18);
    padding-top: calc(55rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}
.fullwidth-teaser__image{
    max-width: calc(640rem/16);
    margin: 0 auto;
}