.btn.btn-primary:focus {
    background-color: var(--color-primary-dark);
    color:#fff;
    box-shadow: 0 4px 10px 0 rgba(17,20,24,0.15);
}
.btn-facebook {
    background-color:#005AE1;
    color:#fff;
}
.btn-google {
    background-color:#CE2318;
    color:#fff;
}

.btn {
    transition: background-color 0.3s ease, color 0.3s ease;
    letter-spacing: calc(1rem/16);
    box-shadow: 0 4px 10px 0 rgba(17,20,24,0.15);
}
.btn-outline-light:hover {
    background-color:#fff;
    color: var(--color-primary);
    border-color: var(--color-primary);
}
.btn.btn-white{
    color: var(--color-default);
}
.theme--dark .btn.btn-white{
    color: var(--color-dark);
}
.btn.btn-icon-round{
    width: calc(35rem/16);
    height: calc(35rem/16);
    font-size: calc(13rem/16);
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 200ms ease-in-out;
    @media screen and (min-width: 768px){
        width: calc(45rem/16);
        height: calc(45rem/16);
        font-size: calc(16rem/16);
    }
}
.btn.btn-icon-round:hover,
.btn.btn-icon-round:focus-visible{
    transform: scale(1.1);
}
.btn.btn-icon-round:focus{
    box-shadow: none;
}

.btn.btn-slider-arrow{
    border: none;
    background: #FFF;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 calc(4rem/16) calc(10rem/16) 0 rgba(17,20,24,0.15);
    transition: transform 200ms ease-in-out;
    position: absolute;
    padding: 0;
    @media screen and (min-width: 768px){
        width: 45px;
        height: 45px;
    }
}
.btn.btn-slider-arrow:hover,
.btn.btn-slider-arrow:focus-visible{
    transform: scale(1.1);
}

.btn.btn-icon-text{
    display: flex;
    align-items: center;
}
.btn.btn-icon-text .icon{
    font-size: calc(24rem/16);
    line-height: 1;
}

/*sizes*/
.btn.btn--sm{
    font-size: calc(16rem/16);
    line-height: 1;
    padding: calc(10rem/16) calc(14rem/16);
    @media screen and (min-width: 768px){
        padding: calc(10rem/16) calc(24rem/16);
    }
}
.btn.btn--wide{
    padding: calc(10rem/16) calc(60rem/16);
    width: 100%;
    font-size: calc(18rem/16);
    line-height: calc(16/18);
    justify-content: center;
    @media screen and (min-width: 768px){
        width: auto;
        padding: calc(10rem/16) calc(100rem/16);
    }
}

/*dropdown toggle*/
.dropdown .dropdown-toggle:after{
    content: var(--icon-dropdown);
    border: none;
    font-family: iconfont;
}

/*btn footer*/
.btn.btn-footer{
    padding: calc(3rem/16) 0;
    box-shadow: none;
    font-size: calc(16rem/16);
    line-height: calc(22/16);
    border: none;
    position: relative;
    display: block;
    width: fit-content;
    letter-spacing: normal;
    text-align: left;
}
.btn.btn-footer:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(2rem/16);
    background-color: var(--color-primary);
    transition: transform 200ms ease-in-out;
    transform: scaleX(0);
    transform-origin: center;
}
.btn.btn-footer:hover:before{
    transform: scaleX(1);
}
.btn.btn-footer.btn-footer--icon{
    padding-left: calc(25rem/16);
    position: relative;
}
.btn.btn-footer.btn-footer--icon .btn-footer__icon{
    font-size: calc(15rem/16);
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: calc(15rem/16);
    width: auto;
}

/*btn underline*/
.btn.btn-underline{
    font-size: calc(14rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    letter-spacing: calc(1rem/16);
    border: none;
    border-radius: 0;
    background: transparent;
    padding: 0 0 calc(6rem / 16);
    position: relative;
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        line-height: calc(19/16);
    }
}
.btn.btn-underline:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(2rem/16);
    background-color: #FFFFFF;
    transition: transform 100ms ease-in-out;
    transform-origin: bottom;
}
.btn.btn-underline:focus-visible:before,
.btn.btn-underline:hover:before{
    transform: scaleY(2);
}
.btn.btn-underline.btn-white{
    color: #FFFFFF;
}
.btn.btn-underline.btn-white:before{
    background-color: #FFFFFF;
}
.btn.btn-white:focus,
.btn.btn-white:focus-visible{
    background-color: var(--color-grey);
    box-shadow: 0 4px 10px 0 rgba(17,20,24,0.4);
}