.teaser-slider-sm__container{
    margin-top: calc(36rem/16);
    margin-bottom: calc(36rem/16);
    position: relative;
    margin-left: -22%;
    @media screen and (min-width: 768px){
        margin-left: calc(-400rem/16);
    }
}
.teaser-slider-sm__container.teaser-slider-sm__container--wide{
    margin-left: 0;
}
.teaser-slider-sm__slider .slick-slide{
    margin: 0 calc(8rem/16);
    width: calc(232rem/16);
    @media screen and (min-width: 768px){
        width: calc(360rem/16);
        margin: 0 calc(20rem/16);
    }
}
.teaser-slider-sm__slider .slick-list{
    margin: 0 calc(-8rem / 16) 0 calc(-20rem / 16);
    @media screen and (min-width: 768px){
        margin: 0 calc(-20rem/16);
    }
}
.teaser-slider-sm__slider.teaser-slider-sm--wide .slick-list{
    margin-left: calc(30rem/16);
    @media screen and (min-width: 768px){
        margin-left: calc(20rem/16);
    }
    @media screen and (min-width: 1600px){
        margin-left: clamp(calc(20rem/16), 9vw, calc(171rem/16));
    }
}
.teaser-slider-sm__container .slick-dots{
    display: none;
    height: 0;
    visibility: hidden;
}
.teaser-slider-sm__arrow.arrow-prev{
    right: 66%;
    top: 20%;
    @media screen and (min-width: 768px){
        right: 61%;
        top: 30%;
    }
}
.teaser-slider-sm__arrow.arrow-next{
    right: 5%;
    top: 20%;
    @media screen and (min-width: 768px){
        right: 2%;
        top: 30%;
    }
}
.teaser-slider-sm__container--wide .teaser-slider-sm__arrow.arrow-prev{
    left: 5%;
    top: 20%;
    @media screen and (min-width: 768px){
        left: 2%;
        top: 30%;
    }
}