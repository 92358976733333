.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    font-family: var(--font-default-bold);
    position: relative;
}
.wysiwyg a:after {
    content: '';
    background-color: var(--color-primary);
    width: 100%;
    height: calc(2rem/16);
    position: absolute;
    top: 100%;
    left: 0;
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
}

.wysiwyg ul {
    padding-left: 0;
    list-style: none;
}

.wysiwyg ul>li {
    padding-left: 1.2rem;
    text-indent: -1.1rem;
}

.wysiwyg ul>li:before {
    display: inline-block;
    content: ' ';
    width: calc(5rem/16);
    height: calc(5rem/16);
    background-color: var(--color-default);
    border-radius: 50%;
    margin-right: calc(9rem/16);
    margin-bottom: calc(2rem/16);
}

.wysiwyg ul > li + li {
    margin-top: calc(15rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(20rem/16);
    }
}