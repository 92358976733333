.hero-portal{
    position: relative;

}
.hero-portal__wrapper{
    overflow: hidden;
    @media screen and (min-width: 768px){
        max-height: 100vh;
    }
    @media screen and (max-width: 767px){
        height: calc(900rem/16);
        background-color: var(--color-default);
    }
}
.hero-portal__image{
    position: relative;
}
.hero-portal__image:before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(17,20,24,0) 0%, #111418 100%);
    z-index: 1;
}

.hero-portal__content{
    z-index: 1;
    position: relative;
    margin-top: calc(-730rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(-450rem/16);
    }
    @media screen and (min-width: 1400px){
        margin-top: calc(-500rem/16);
    }
    @media screen and (max-width: 767px){
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: calc(850rem/16);
    }
}
.hero-portal__info-container{
    margin-bottom: calc(50rem/16);
    @media screen and (max-width: 767px){
        margin-bottom: calc(140rem/16);
    }
}

/*hero info*/
.hero-portal__info{
    text-align: center;
    color: #FFFFFF;
}
.hero-portal__info-title{
    font-size: calc(16rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        line-height: calc(19/20);
    }
}
.hero-portal__info-icon{
    font-size: calc(60rem/16);
    margin-top: calc(30rem/16);
    line-height: 1;
    display: none;
    min-height: calc(70rem/16);
    @media screen and (min-width: 768px){
        display: block;
    }
}
.hero-portal__info-list{
    margin-top: calc(13rem/16);
    margin-bottom: calc(20rem/16);
}
.hero-portal__info-list li.list-inline-item{
    margin-right: 0;
}
.hero-portal__info-list li + li{
    margin-left: calc(19rem/16);
    padding-left: calc(20rem/16);
    border-left: calc(1rem/16) solid #FFFFFF;
}
.hero-portal__info-list__title{
    font-size: calc(22rem/16);;
    line-height: 1;
    font-family: var(--font-default-bold);
    @media screen and (min-width: 1200px){
        font-size: calc(26rem/16);;
    }
}
.hero-portal__info-list__text{
    font-size: calc(12rem/16);
    line-height: 1;
    margin-top: calc(10rem/16);
    @media screen and (min-width: 1200px){
        font-size: calc(16rem/16);
    }
}

/*Webcam*/
.hero-portal__webcam{
    max-width: calc(152rem/16);
    margin: calc(17rem/16) auto calc(19rem/16) auto;
    border-radius: calc(8rem/16);
    overflow: hidden;
    position: relative;
    @media screen and (min-width: 768px){
        max-width: calc(227rem/16);
        margin: calc(29rem/16) auto calc(21rem/16) auto;
    }
}
.hero-portal__webcam:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(17,20,24,0) 0%, rgba(17,20,24,0.8) 100%);
    z-index: 1;
}
.hero-portal__webcam-img{
    transition: transform 200ms ease-in-out;
}
.hero-portal__webcam:hover .hero-portal__webcam-img{
    transform: scale(1.05);
}
.hero-portal__webcam-content{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
}
.hero-portal__webcam-title{
    font-size: calc(12rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    position: absolute;
    width: 100%;
    bottom: calc(14rem/16);
    left: 0;
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        line-height: calc(20/16);
        bottom: calc(20rem/16);
    }
}

/*teaser area*/
.hero-portal__teaser-row-container{
    @media screen and (min-width: 768px){
        min-height: calc(150rem/16);
    }
}
.hero-portal__teaser-row{
    @media screen and (max-width: 767px){
        overflow-x: scroll;
        flex-wrap: nowrap;
        scroll-snap-type: x mandatory;
        scroll-padding-left: calc(30rem/16);
        padding-left: calc(30rem/16);
    }
}
.hero-portal__teaser-row div{
    scroll-snap-align: start;
}

/*cta*/
.hero-portal__cta{
    margin-top: calc(30rem/16);
    margin-left: calc(30rem/16);
    margin-right: calc(30rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(35rem / 16);
        margin-left: 0;
        margin-right: 0;
    }
}