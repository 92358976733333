.live-info-body {
    margin-bottom: calc(150rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(40rem/16);
    }
}
.live-info-body__inner {
    padding: 0 calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}
