.media-area__title{
    font-size: calc(25rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    color: #FFFFFF;
    position: absolute;
    bottom: calc(26rem/16);
    text-align: center;
    left: 0;
    width: 100%;
    height: fit-content;
    padding: 0 calc(100rem/16);
}
.vjs-has-started + .media-area__title{
    display: none;
}
.media-area__container{
    position: relative;
    overflow: hidden;
}
.media-area .video-js:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(17,20,24,0) 0%, rgba(17,20,24,0.8) 100%);
    z-index: 1;
    pointer-events: none;
}
.media-area .video-js.vjs-has-started:before{
    opacity: 0;
}
.media-area__container .vjs-big-play-button,
.media-area__container .media-area__title{
    z-index: 1;
}