.hero-content{
    margin-top: calc(53rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(126rem/16);
    }
}
.hero-content__container{
    background-color: var(--color-dark-grey);
    color: #FFFFFF;
    display: flex;
    justify-content: flex-end;
    border-radius: calc(8rem/16);
    overflow: hidden;
    position: relative;
    flex-direction: column;
    @media screen and (min-width: 768px){
        flex-direction: row;
    }
}
.hero-content__img{
    max-width: calc(1200rem/16);
    width: 100%;
    position: relative;
}
.hero-content__img:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(200rem/16);
    background: linear-gradient(0deg, rgba(111,117,125,0) 0%, #6F757D 100%);
    z-index: 1;
    @media screen and (min-width: 768px){
        height: 100%;
        background: linear-gradient(270deg, rgba(111,117,125,0) 0%, var(--color-dark-grey) 100%);
    }
}
.hero-content__content{
    z-index: 2;
    padding: calc(35rem/16) calc(20rem/16) 0 calc(20rem/16);
    @media screen and (min-width: 768px){
        position: absolute;
        left: calc(82rem/16);
        bottom: calc(61rem/16);
        width: calc(815rem/16);
    }
}
.hero-content__title{
    font-size: calc(35rem/16);
    line-height: 1;
    color: #FFFFFF;
    @media screen and (min-width: 768px){
        font-size: calc(80rem/16);
    }
}
.hero-content__subtitle{
    margin-top: calc(10rem/16);
    font-size: calc(20rem/16);
    line-height: calc(24/20);
    @media screen and (min-width: 768px){
        margin-top: calc(21rem/16);
        font-size: calc(30rem/16);
        line-height: calc(36/30);
    }
}