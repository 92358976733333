.form-errors {
    color: var(--color-danger);
}
.form-errors:empty {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.invalid-feedback {
    display:block;
    font-size: calc(12rem/16);
}
.parsley-errors-list{
    position: absolute;
    top: 100%;
}