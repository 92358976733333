.img-slider-sm-teaser__title{
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    line-height: calc(24/20);
}
.teaser-slider-sm-teaser .teaser-slider-sm-teaser__img{
    transition: transform 200ms ease-in-out;
}
.teaser-slider-sm-teaser:hover .teaser-slider-sm-teaser__img{
    transform: scale(1.03);
}

/*badge*/
.teaser-slider-sm-teaser__badge{
    position: absolute;
    top: calc(20rem/16);
    right: 0;
    width: min-content;
    height: min-content;
    padding: calc(7rem/16) calc(10rem/16);
    background-color: var(--color-default);
    color: #FFFFFF;
    border-top-left-radius: calc(4rem/16);
    border-bottom-left-radius: calc(4rem/16);
    font-size: calc(14rem/16);
    line-height: 1;
    text-transform: uppercase;
}
