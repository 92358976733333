.hotspot-teaser{
    background: #FFFFFF;
    overflow: hidden;
    transition: background-color 200ms ease-in-out;
}
.hotspot-teaser + .hotspot-teaser{
    margin-top: calc(10rem/16);
}
.hotspot-teaser.is-active{
    background: var(--color-light-grey);
}
.hotspot-teaser__image-container{
    height: calc(150rem/16);
    position: relative;
    @media screen and (min-width: 768px){
        height: 100%;
    }
    @media screen and (min-width: 1600px){
        margin-right: calc(20rem/16);
    }
}
.hotspot-teaser__image{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.hotspot-teaser__content{
    padding: calc(30rem / 16) calc(15rem / 16) calc(25rem / 16);
    margin-left: calc(15rem/16);
    @media screen and (min-width: 992px){
        padding: calc(15rem / 16) calc(15rem / 16) calc(15rem / 16) 0;
    }
    @media screen and (min-width: 1600px){
        padding: calc(37rem / 16) 0 calc(25rem / 16);
        margin-left: calc(20rem/16);
    }
}
.hotspot-teaser__feature{
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
    gap: calc(15rem/16);
    @media screen and (max-width: 1599px){
        font-size: calc(14rem/16);
    }
    @media screen and (max-width: 1200px){
        font-size: calc(12rem/16);
    }
    @media screen and (max-width: 767px){
        font-size: calc(14rem/16);
    }
}
.hotspot-teaser__feature-icon{
    font-size: calc(16rem/16);
    @media screen and (min-width: 1600px){
        font-size: calc(25rem/16);
    }
}
.hotspot-teaser__buttons{
    width: 100%;
    margin-top: calc(30rem/16);
    display: flex;
    gap: calc(15rem/16);
    flex-direction: column;
    align-items: start;
    @media screen and (min-width: 768px){
        align-items: center;
        flex-direction: row;
    }
}
.hotspot-teaser__buttons .btn{
    font-size: calc(14rem/16);
    @media screen and (min-width: 1200px){
        font-size: calc(16rem/16);
    }
    @media screen and (max-width: 1599px){
        padding: calc(10rem/16) calc(20rem/16);
    }
}