.payment-item {
    background-color: var(--color-white);
    padding: calc(42rem/16) calc(40rem/16) calc(36rem/16);
    color: var(--color-dark);
    position: relative;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.payment-item--has-edit {
    padding: calc(42rem/16) calc(40rem/16) calc(36rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(42rem/16) calc(20rem/16) calc(20rem/16);
    }
}
.payment-item + .payment-item {
    margin-top: calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(20rem/16);
    }
}
.payment-item__headline {
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    line-height: 1;
    margin-top: calc(5rem/16);
    margin-bottom: calc(10rem/16);
    color: var(--color-dark);
}
.payment-item__edit {
    position: absolute;
    right:0;
    top:0;
    letter-spacing: 0;
}