:root {
    --container-max-width: calc(1600rem/16);
    --container-narrow-max-width: calc(1000rem/16);
    --container-small-max-width: calc(802rem/16);
    --container-md-max-width: calc(1300rem/16);
}

.container.container {
    max-width: var(--container-max-width);
    width: 100%;
    @media screen and (max-width: 767px) {
        padding: 0 calc(30rem/16);
    }
}
.container-fluid {
    padding-left: calc(50rem/16);
    padding-right: calc(50rem/16);
    @media screen and (max-width: 767px) {
        padding: 0 calc(15rem/16);
    }
}
.container.container-narrow {
    max-width: var(--container-narrow-max-width);
    width: 100%;
}
.container.container-small {
    max-width: var(--container-small-max-width);
    width: 100%;
}
.container.container-md {
    max-width: var(--container-md-max-width);
    width: 100%;
}
.container.container--no-padding-xs {
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}