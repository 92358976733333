.ticket-teaser.ticket-personalization-teaser {
    display:flex;
    flex-direction: column;
    background-color: transparent;
    color: var(--color-default);
    border: 1px solid var(--color-light-grey);
}
.ticket-teaser.ticket-personalization-teaser:not(.is-personalized) .ticket-teaser__title {
    color: var(--color-default);
}
.ticket-personalization-teaser.is-personalized {
    background-color: var(--color-white);
    color: var(--color-dark);
}
