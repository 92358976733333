.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    text-align: center;
}
.loading-overlay:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content:'';
    width:100%;
    height:100%;
    background: var(--color-theme);
    opacity:0.7;
    z-index: 10;
    text-align: center;
}
.loading-overlay--light:before {
    background: var(--color-white);
}