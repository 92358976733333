.webcam-teaser__body {
    padding: calc(30rem/16) calc(35rem/16);
    background-color: var(--color-white);
}

.webcam-teaser__title {
    font-family: var(--font-default-bold);
    color: var(--color-dark);
    font-size: calc(24rem/16);
}

.webcam-teaser__subtitle {
    margin-top: calc(5rem/16);
    font-size: calc(16rem/16);
    line-height: calc(25rem/16);
}

.webcam-teaser__embed::before {
    padding-top: calc(230 / 1000 * 100%);
    @media (max-width: 767px) {
        padding-top: calc(300 / 400 * 100%);
    }
}

.webcam-teaser__img, .webcam-teaser__title {
    transition: transform 0.3s ease;
}

.webcam-teaser:hover {
    cursor: pointer;
}

.webcam-teaser:hover .webcam-teaser__img {
    transform: scale(1.04);
}
.webcam-teaser:hover .webcam-teaser__title {
    transform: translateY(-5px);
    color: var(--color-primary);
}

.webcam-teaser:hover .video__play-btn {
    background-color: var(--color-primary-transparent);
    border-color: var(--color-dark);
}

.webcam-teaser:hover .video__play-btn-icon {
    color: var(--color-white);
}
