.login-box__box {
    margin: 0 auto;
    color: var(--color-dark) !important;
}
.login-box {
    margin: 0 auto;
    max-width: calc(800rem/16);
    background-color: var(--color-white);
    padding: calc(70rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.login-box .form-control {
    color: var(--color-dark);
}
.login-box__box__block__hl {
    color: var(--color-dark);
}