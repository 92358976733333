.svg-map__map text {
    font-family: var(--font-default) !important;
}
.svg-map-area {
    position: relative;
}
.svg-map__embed:before {
    padding-top: calc(900 / 1920 * 100%);
}
.svg-converter__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: calc(30rem / 16) 0;
    top: 10vw;
    width: 100%;
    @media screen and (max-width: 1199px) {
        top: 0;
    }
    @media screen and (max-width: 767px) {
        position: relative;
    }
}
.svg-converter__inner {
    @media screen and (min-width: 768px) {
        max-width: 50%;
    }
}
.svg-map__embed svg {
    width: 100%;
    height: 100%;
}