/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }


/* Background Color Helper */
.bg-white{
    color: #ffffff;
}


/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(12rem/16);
    margin: calc(-12rem/16);
}

.text-underline-hover:hover {
    text-decoration: underline;
}

/* Font Helper */
.font-bold {
    font-family: var(--font-default-bold);
}

.font-medium {
    font-family: var(--font-default);
}

.font-light {
    font-family: var(--font-default-light);
}

.fz26 {
    font-size: calc(26rem/16);
}
.fz20 {
    font-size: calc(20rem/16);
}
.fz12 {
    font-size: calc(12rem/16);
}
.fz14 {
    font-size: calc(14rem/16);
}
.fz15 {
    font-size: calc(15rem/16);
}
.lh-1 { line-height: 1;}
.lh--medium { line-height: 1.4;}
.z-1{
    z-index: 1;
}
.z-3{
    z-index: 3;
}

.rounded-corners{
    border-radius: calc(8rem/16);
}
.w-md-100{
    @media screen and (min-width: 768px){
        width: 100%;
    }
}

.pe-none {
    pointer-events: none;
}