.custom-nav-tabs {
    border-bottom: none;
    display: flex;
    justify-content:space-around;
    @media screen and (max-width: 767px) {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        letter-spacing: normal;
        flex-wrap: nowrap;
        justify-content: inherit;
        padding-bottom: calc(20rem/16);
    }
}
.custom-nav-tabs__sub-title {
    font-size: calc(16rem/16);
    line-height: calc(19/16);
    text-align:center;
    display:block;
    font-family: var(--font-default);
    letter-spacing: 0;
    margin-bottom: 0.2rem;
}
.custom-nav-tabs__link {
    font-family: var(--font-default-bold);
    font-size: calc(30rem/16);
    letter-spacing: calc(1.8rem/16);
    text-align: center;
    position: relative;
    border-bottom: calc(2rem/16) solid var(--color-text-muted);
    color: var(--color-text-muted);
    padding: 0;
    line-height: 1.4;
    transition: color 0.2s ease;
    padding-bottom: 0.3rem;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
        font-size: calc(18rem/16);
    }
}
.custom-nav-tabs__link.active {
    color: #000;
    border-color: var(--color-primary);
}
.custom-nav-tabs__link:hover {
    color: #000;
}
.custom-nav-tabs__link:before {
    border: solid transparent;
    border-top-color: var(--color-primary);
    border-width:calc(15rem/16);
    margin-left:calc(-15rem/16);
    top: 100%;
    left: 0;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease, left 300ms ease;
}
.custom-nav-tabs__link:after {
    border: solid transparent;
    border-top-color: #ffffff;
    border-width: calc(14rem/16);
    margin-left: calc(-14rem/16);
    top: 99%;
    left: 0;
    content: " ";
    height: 0;
    width: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    transition: opacity 300ms ease, left 300ms ease;
}
.active.custom-nav-tabs__link:after {
    border: solid transparent;
    border-top-color: var(--color-theme);
    border-width: calc(14rem/16);
    margin-left: calc(-14rem/16);
    top: 99%;
    left: 50%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    opacity: 1;
}
.bg-white .active.custom-nav-tabs__link:after {
    border-top-color: #fff;
}
.active.custom-nav-tabs__link:before {
    border: solid transparent;
    border-top-color: var(--color-primary);
    border-width: calc(16rem/16);
    margin-left: calc(-16rem/16);
    top: 100%;
    left: 50%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    opacity: 1;
}