.live-console {
    position: absolute;
    width: calc(104rem/16);
    height: 100%;
    margin-left: calc(18rem/16);
    top: 0;
    color:#fff;
    letter-spacing: calc(2rem/16);
    text-align:center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    @media screen and (max-width: 767px) {
        position: relative;
        width: 100%;
        margin-left: 0;
        padding: 0 calc(20rem/16);
        letter-spacing: calc(1rem/16);
    }
}
.theme--light .live-console {
    @media screen and (max-width: 767px) {
       color: var(--color-text-default);
    }
}
.live-console__list {
    @media screen and (max-width: 767px) {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}
.live-console__list-item__icon {
    display:block;
}
.live-console__list-item {
    padding: calc(20rem/16) 0;
    font-size: calc(16rem/16);
    line-height: calc(19/16);
    text-shadow: calc(2rem/16) calc(2rem/16) calc(5rem/16) #000000;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        padding: calc(20rem/16) calc(5rem/16);
        flex-basis:0;
        text-shadow: none;
    }
}
.live-console__list-item__icon {
    font-size: calc(30rem/16);
    margin-bottom: calc(10rem/16);
}
@media screen and (min-width: 768px) {
    .live-console__list-item:hover .live-console__list-item__icon {
        transform: scale(1.3);
    }
    .live-console__list-item__icon {
        transition: transform 0.3s ease;
    }
}