.wysiwyg table th, .table th {
    letter-spacing: calc(1rem/16);
    font-size: calc(16rem/16);
    line-height: calc(19/16);
    color: #FFF;
    background-color: var(--color-dark-grey);
    border-top:none;
}
.wysiwyg table, .wysiwyg .table, table.table{
    border-radius: calc(8rem/16);
    overflow: hidden;
    font-size: calc(20rem/16);
    line-height: calc(26rem/16);
}
table.table thead th, .wysiwyg table thead th {
    border-bottom-width: 2px;
    text-transform: none;
}
@media screen and (max-width: 767px){
    table.table th, .wysiwyg table th{
        font-size: calc(14rem/16);
        line-height: calc(19/14);
        padding: calc(20rem/16);
    }
    table.table tbody td, .wysiwyg table tbody td{
        font-size: calc(16rem/16);
    }
    table.table.table--price tbody td, .wysiwyg table.table--price tbody td {
        text-align: right;
    }
}