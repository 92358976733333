.weather-item__info{
    text-align: center;
    font-size: calc(10rem/16);
    line-height: calc(16/10);
    @media screen and (min-width: 1200px){
        font-size: calc(14rem/16);
        line-height: calc(16/14);
    }
}
.weather-item__info .weather-item__info-top{
    font-size: calc(16rem/16);
    line-height: calc(26/16);
    margin-bottom: calc(5rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        line-height: calc(26/20);
    }
}

.weather-item__info--lg{
    text-align: center;
    font-size: calc(16rem/16);
    line-height: calc(26/16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        line-height: calc(26/20);
    }
}
.weather-item__info--lg .weather-item__info-top{
    line-height: 1;
    font-size: calc(40rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(60rem/16);
    }
}
.weather-item__container{
    background-color: #FFFFFF;
    border-radius: calc(8rem/16);;
    padding: calc(15rem/16) calc(20rem/16) calc(16rem/16) calc(20rem/16);
    @media screen and (min-width: 1200px){
        padding: calc(26rem/16) calc(30rem/16) calc(28rem/16) calc(30rem/16);
    }
}
.weather-item__boxes {
    margin-top: calc(18rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(42rem/16);
    }
}
.weather-item__title{
    letter-spacing: calc(1rem/16);
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    line-height: calc(19/14);
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        line-height: calc(19/16);
    }
}
.weather-item__title.news{
    border-bottom: 1px solid var(--color-default);
    padding-bottom: 1rem;
}
.weather-item__container.weather-item__container--half{
    position: relative;
}
.weather-item__container.weather-item__container--half:before{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: calc(1rem/16);
    background-color: var(--color-grey);
}