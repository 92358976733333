.live-info__content {
    padding-top: calc(70rem/16);
    padding-bottom: calc(70rem/16);
    border: 1px solid var(--color-text-default);
    border-top: none;
    background-color: var(--color-theme);
    margin-top: calc(-1rem/16);
}
.live-info__box {
    padding: calc(25rem/16);
    font-size: calc(20rem/16);
    line-height: 1.4;
    border: 0px solid var(--color-text-default);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        padding: calc(10rem/16);
    }
}
.live-info__box + .live-info__box {
    border-top: none;
}
.live-info__box-row--header {
    font-family: var(--font-default-bold);
}
.live-info__box-row {
    padding-top: calc(6rem/16);
    padding-bottom: calc(6rem/16);
}
.live-info__box-row:last-of-type {
    padding-bottom: 0;
}
.live-info__box-row:first-of-type {
    padding-top: 0;
}
.live-info__facility-list {
    font-size: calc(18rem/16);
    line-height:1;
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        line-height:1.2;
    }
}
.live-info__facility-list .icon{
    font-size: calc(24rem/16);
}
.live-info__title {
    letter-spacing: 0;
}

.live-info__box-item--border-right {
    border-right: 1px solid var(--color-text-default);
}
.live-info__weather-img {
    max-height: calc(100rem/16);
}
.live-info__weather-icon {
    font-size: calc(70rem/16);
    display: block;
    text-align:center;
}
.live-info__mountain-item {
    line-height:1.2;
    margin-bottom: calc(2rem/16);
}
.live-info__mountain-item:after {
    content:'';
    height: calc(1rem/16);
    width: 200%;
    display: block;
    background-color:#fff;
    position: absolute;
    left: calc(15rem/16);
}