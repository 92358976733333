html.is-scrolling-disabled,
html.is-scrolling-disabled body {
    overflow: hidden;
}
html.is-scrolling-disabled body {
    position: relative;

    @media screen and (max-width: 767px) {
        position: fixed;
        left: 0;
        right: 0;
    }
}
