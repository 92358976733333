.floating-label {
    left: calc(22rem/16);
    top: 50%;
    transform: translateY(-50%);
}

.select .floating-label{
    width: 90%;
}
.text-area .floating-label{
    top: calc(23rem/16);
}

.form-control.has-value~.floating-label, .form-control:focus~.floating-label, .form-group.has-value .floating-label, select~.floating-label, textarea.form-control.has-value~.floating-label, textarea.form-control:focus~.floating-label{
    transform: translateY(-1.2rem) scale(.75);
}