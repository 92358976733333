.modal {
    color: var(--color-dark);
}
.modal h1,.modal h2,.modal h3,.modal h4,.modal h5,.modal h6,
.modal .h1,.modal .h2,.modal .h3,.modal .h4,.modal .h5,.modal .h6, .modal .form-control {
    color: var(--color-dark);
}

.modal-body .download-portal__modal-content a {
    color: var(--color-primary);
}