.mini-slider__embed:before {
    padding-top: calc( 180 / 237 * 100%);
}
.mini-slider__wrapper {
    position: relative;
}
.mini-slider__wrapper, .mini-slider__item {
    max-width: calc(588rem/16);
}
.mini-slider__item {
    padding-top: calc(45rem/16);
    cursor:pointer;
}
.mini-slider__media-wrapper {
    @media screen and (max-width: 767px) {
        padding: 0 calc(30rem/16);
    }
}
.mini-slider__content {
    color: var(--color-default);
    padding-left: calc(70rem/16);
    padding-top: calc(50rem/16);
    padding-right: calc(50rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) 0 0;
        display:flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
    }
}
.mini-slider__title {
    font-size: calc(24rem/16);
    line-height:1;
    font-family: var(--font-default-bold);
    margin-bottom: calc(8rem/16);
    @media screen and (max-width: 767px) {
        padding: 0 calc(30rem/16);
    }
}
.mini-slider__subtitle {
    font-size: calc(16rem/16);
    line-height: calc(25/16);
    @media screen and (max-width: 767px) {
        padding: 0 calc(30rem/16);
    }
}
.mini-slider__counter {
    font-size: calc(60rem/16);
    letter-spacing: calc(4rem/16);
    line-height: calc(72/60);
    font-family: var(--font-default-bold);
    color: var(--color-primary);
    position: absolute;
    left:0;
    top: 0;
    transform: translateY(-50%);
    display: grid;
    width: 100%;
    text-align: center;
    grid-template-columns: calc(50rem/16) auto minmax(20px,1fr);
    grid-gap: calc(10rem/16);
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
        position: relative;
        transform: translateY(0);
        margin-bottom: calc(10rem/16);
        margin-top: auto;
        padding-top: calc(20rem/16);
        font-size: calc(48rem/16);
        height: calc(94rem/16);
    }
}
.mini-slider__counter:before {
    content: "";
    margin-top:1px;
    border-top: 1px solid var(--color-default);
}
.mini-slider__counter:after {
    content: "";
    margin-top:1px;
    border-top: 1px solid var(--color-default);
}
.mini-slider__arrow-wrapper .slick-arrow {
    display:block;
}
.mini-slider__arrow-wrapper {
    position:absolute;
    right:0;
    top:0;
    @media screen and (max-width: 767px) {
        bottom:0;
        top: initial;
        right: calc(30rem/16);
    }
}