table.table.accordion-table__table{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-width: 0;
}
.accordion-table__btn{
    border-radius: calc(8rem/16);
    width: 100%;
    background-color: var(--color-dark-grey);
    color: #FFF;
    border: none;
    outline: none;
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(20rem/16) calc(16rem/16);
    font-size: calc(18rem/16);
    line-height: calc(19/18);
}
.accordion-table__btn:not(.collapsed){
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.accordion-table__btn:hover,
.accordion-table__btn:focus-visible,
.accordion-table__btn:focus{
    outline: none;
    background-color: var(--color-dark-grey-light);
}