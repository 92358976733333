.custom-styled-radio {
    color: var(--color-default);
    padding-left:0;
}
.custom-styled-radio .custom-radio__label {
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
}
.custom-styled-radio .custom-radio__sub-text {
    font-size: calc(16rem/16);
    margin-bottom: calc(6rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.custom-styled-radio .custom-radio__text {
    font-size: calc(30rem/16);
    line-height: 1;
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.custom-styled-radio .custom-radio__box {
    display:none;
}
.custom-styled-radio .custom-styled-radio__line:before {
    border: solid transparent;
    border-top-color: var(--color-default);
    border-width: 15px;
    top: 100%;
    transform: translateX(-50%);
    left: 50%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    opacity: 1;
}
.custom-styled-radio .custom-styled-radio__line:after {
    border: solid transparent;
    border-width: calc(14rem/16);
    margin-left: calc(-14rem/16);
    margin-bottom:0;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    opacity: 1;
    border-top-color: var(--color-theme);
}
.custom-styled-radio .custom-styled-radio__line {
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    bottom: 0;
    left:0;
    z-index: 3;
    background: var(--color-default);
    opacity:0;
    transform: translateY(calc(20rem/16));
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.custom-styled-radio .custom-radio__input:checked + .custom-styled-radio__line, .custom-styled-radio:hover .custom-styled-radio__line {
    opacity:1;
    transform: translateY(calc(0rem/16));
}