.contact-teaser {
    padding: calc(20rem/16);
    background-color: #FFFFFF;
    min-height: calc(220rem/16);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (min-width: 992px) {
        min-height: calc(328rem/16);
        padding: calc(1.25rem + ((1vw - 0.62rem) * 3.4274));
        /* Where: * 3.4274 = 100 * padding_difference (34) / viewport_Width_Difference (992) */
    }
    @media (min-width: 1920px) {
        padding: calc(40rem/16);
    }
}

.contact-teaser--border {
    border: 1px solid var(--color-text-default);
}

.contact-teaser__subtitle{
    margin-bottom: 0;
    font-size: calc(16rem/16);
    line-height: calc(24/16);
    color: var(--color-default);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        line-height: calc(24/20);
    }
}

.contact-teaser__name {
    font-size: calc(20rem/16);
    line-height: calc(24/20);
    font-family: var(--font-default);
    margin-bottom: calc(16rem/16);
    margin-top: calc(4rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: calc(18rem/16);
        margin-top: calc(8rem/16);
        font-size: calc(30rem/16);
        line-height: calc(36/30);
    }
}
.contact-teaser__email {
    margin-top: calc(24rem/16);
    word-break: break-all;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(16rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(21rem/16);
        width: 100%;
    }
}