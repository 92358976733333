:root {
    --second-level-bg: #fff;
    --second-level-color: #000;
}

.main-nav__second-level {
    background-color: var(--second-level-bg);
    color: var(--second-level-color);
    z-index: 10;
    position:relative;
    display: block;
    transition: opacity 200ms ease-in-out;
    @media screen and (min-width: 768px) {
        opacity: 0;
        position: absolute;
        top: 0;
        left: calc(260rem/16);
        width: 60vw;
        pointer-events: none;
    }
    @media screen and (min-width: 992px){
        left: calc(350rem/16);
    }
    @media screen and (min-width: 1400px){
        left: calc(532rem/16);
        width: 40vw;
    }
}
.main-nav__second-level--right {
    @media screen and (min-width: 768px) {
        right: initial;
        left:0;
    }
}
.main-nav__second-level--small {
    @media screen and (min-width: 768px) {
        padding: calc(20rem/16);
        min-width: calc(200rem / 16);
    }
}
.main-nav__second-level--not-in-overlay {
    @media screen and (max-width: 767px) {
        padding: calc(0rem/16) calc(20rem/16) calc(20rem/16);
        min-width: calc(200rem / 16);
        position: absolute;
        box-shadow: 0 calc(4rem / 16) calc(14rem / 16) 0 rgba(0, 0, 0, .2);
        top: calc(50rem/16);
        left:0;
    }
}
.main-nav__second-level--not-in-overlay .main-nav__second-level__nav {
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
    }
}
.main-nav__second-level--full-width {
    left:0;
    right: 0;
    width:100%;
    @media screen and (min-width: 768px) {
        box-shadow: none;
        border-top: calc(1rem/16) solid var(--color-light-grey);
        padding: calc(70rem/16) 0;
    }
}
@media screen and (min-width: 768px) {
    .main-nav__second-level.show-menu .main-nav__second-level, .main-nav__item.is-open .main-nav__second-level, .main-nav__second-level.show {
        opacity: 1;
       pointer-events: auto;
    }
}

.main-nav__second-level__item {
    letter-spacing: 0;
    font-size: calc(20rem/16);
    line-height: calc(40/20);
    @media screen and (max-width: 767px) {
        line-height: 2;
        font-size: calc(16rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        font-size: calc(18rem/16);
    }
}
.main-nav__third-level__item {
    letter-spacing: 0;
    font-size: calc(14rem/16);
    line-height: calc(22/14);
    color: var(--color-dark-grey);
}
.main-nav__third-level__item:hover,
.main-nav__third-level__item:focus-visible {
    color: var(--color-primary);
}
.main-nav__third-level__item:not(.main-nav__third-level__main-item){
    margin-top: calc(20rem/16);
}
.main-nav__second-level__item.main-nav__second-level__main-item, .main-nav__third-level__item.main-nav__third-level__main-item {
    font-size: calc(20rem/16);
    line-height: calc(22/20);
    margin-bottom: calc(20rem/16);
    font-family: var(--font-default-bold);
    letter-spacing:0;
    @media screen and (max-width: 767px) {
        margin-top: calc(32rem/16);
        font-size: calc(18rem/16);
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        font-family: var(--font-default);
    }
}
.main-nav__second-level__main-item .main-nav__link,
.main-nav__third-level__main-item .main-nav__link{
    font-family: var(--font-default);
    line-height: calc(22/20);
}
.main-nav__second-level__nav {
    @media screen and (max-width: 767px) {
        padding-top: calc(10rem/16);
    }
}
.main-nav__third-level__nav  {
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        line-height: 2;
    }
}
@media screen and (max-width: 767px) {
    .main-nav__second-level .main-nav__link, .main-nav__third-level__item .main-nav__link {
        flex-grow: 1;
    }

    .main-nav__third-level__item > a {
        display: block;
    }
}
.main-nav__second-level-list.main-nav__second-level-list--columns{
    @media screen and (min-width: 768px){
        column-count: 3;
    }
}
.main-nav__second-level-list > li{
    break-inside: avoid-column;
}
.main-nav__second-level-list > li .main-nav__third-level__main-item{
    @media screen and (max-width: 767px){
        margin-top: calc(31rem/16);
    }
}
.main-nav__second-level-list > li + li .main-nav__third-level__main-item{
    margin-top: calc(31rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(42rem/16);
    }
}
.main-nav__third-level__item.main-nav__third-level__main-item .main-nav__link{
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        line-height: calc(22rem/16);
    }
}