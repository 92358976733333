/* modal fix */
.ui-datepicker {
    z-index: 9999 !important;
}
.ui-datepicker table {
    font-size: calc(12rem/16);
}
.ui-datepicker {
    @media screen and (max-width: 767px) {
        width: calc(280rem/16);
    }
}