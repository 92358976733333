/*main footer*/

.main-footer{
    padding-top: calc(8rem/16);
    padding-bottom: calc(20rem/16);
    @media screen and (min-width: 768px){
        padding-top: calc(75rem/16);
        padding-bottom: calc(65rem/16);
    }
}
.main-footer__title{
    font-size: calc(18rem/16);
    line-height: calc(21/18);
    letter-spacing: normal;
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        line-height: calc(24/20);
    }
}
.main-footer__collapse{
    @media screen and (max-width: 767px){
        border-bottom: calc(2rem/16) solid var(--color-grey);
    }
}
.main-footer__collapse-btn{
    background: none;
    border: none;
    @media screen and (min-width: 768px){
        pointer-events: none;
        padding: 0;
        margin-bottom: calc(16rem/16);
    }
    @media screen and (max-width: 767px){
        padding: calc(26rem/16) 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}
.main-footer__collapse-icon{
    font-size: calc(12rem/16);
    color: var(--color-default);
    transition: transform 200ms ease-in-out;
    @media screen and (min-width: 768px){
        display: none;
    }
}
.main-footer__collapse-btn[aria-expanded="true"] .main-footer__collapse-icon{
    transform: rotate(180deg);
}
.main-footer__collapse-btn[aria-expanded="true"] .main-footer__collapse-icon:before{
    content: var(--icon-minus);
}
.main-footer__collapse-btn:focus{
    box-shadow: none;
    outline: none;
}

/*meta footer*/
.meta-footer {
    padding: calc(35rem/16) 0;
    border-top: calc(2rem/16) solid var(--color-grey);
    @media screen and (min-width: 768px){
        display: flex;
        justify-content: space-between;
    }
}
.meta-footer__elements-logo {
    width: calc(165rem/16);
    height: calc(18rem/16);
    @media screen and (min-width: 768px){
        width: calc(184rem/16);
        height: calc(20rem/16);
    }
}